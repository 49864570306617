import { z } from 'zod'

import noLeadingSpaces from '../../../../../utilities/noLeadingSpaces'

const MAX_FILE_SIZE = 5000000

export const ACCEPTED_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf',
]
export const handleSchema = (method?: string, data?: any) => {
  const paymentSchema = z.object({
    payment_date: z
      .union([z.date(), z.string(), z.null()])
      .refine((val) => val !== null, {
        message: 'Transaction date is required',
      }),

    transaction_number: z
      .string()
      .min(1, { message: 'Transaction id is required.' })
      .refine(noLeadingSpaces, {
        message: 'Leading spaces are not allowed',
      }),
    // amount: z
    //   .number({ invalid_type_error: 'Amount is required' })
    //   .min(1, { message: 'Amount should be greater than 0' }),
    amount: z.number().optional(),
    bizpole_payable_amount: z.number().optional(),
    paymentout_type: z.string().refine((val) => val !== '', {
      message: 'Payment type is required',
    }),
    paymentout_type_name: z.string().refine((val) => val !== '', {
      message: 'Payment type is required',
    }),
    original_document: z.string().optional().nullable(),
    paymentout_method_id: z.string().refine((val) => val !== '', {
      message: 'Mode of payment is required',
    }),
    paymentout_method_name: z.string().refine((val) => val !== '', {
      message: 'Mode of payment is required',
    }),
    remark: z.string().optional(),
    receipt_number: z
      .string()
      .min(1, { message: 'Receipt number is required.' })
      .refine(noLeadingSpaces, {
        message: 'Leading spaces are not allowed',
      }),

    ...(method === 'cash' || data?.paymentout_method?.code === 'cash'
      ? {
          bank_account_id: z
            .string()
            .refine(noLeadingSpaces, {
              message: 'Leading spaces are not allowed',
            })
            .optional()
            .nullable(),
          bank_account_name: z
            .string()
            .refine(noLeadingSpaces, {
              message: 'Leading spaces are not allowed',
            })
            .optional()
            .nullable(),
        }
      : {
          bank_account_id: z
            .string()
            .min(1, { message: 'Bank account is required.' })
            .refine(noLeadingSpaces, {
              message: 'Leading spaces are not allowed',
            }),
          bank_account_name: z
            .string()
            .min(1, { message: 'Bank account is required.' })
            .refine(noLeadingSpaces, {
              message: 'Leading spaces are not allowed',
            }),
        }),

    payment_document: z
      .union([z.instanceof(File), z.any()])
      .refine((file) => !(file instanceof File) || file.size <= MAX_FILE_SIZE, {
        message: 'Maximum size 5mb',
      })
      .refine(
        (file) =>
          !(file instanceof File) || ACCEPTED_IMAGE_TYPES.includes(file.type),
        {
          message: 'File type not allowed',
        }
      )
      .optional(),
  })
  return paymentSchema
}
