import { isAllFranchisee } from '../../configs/permissionGate'
import { convertDateFormat } from '../../utilities/format'
import { getNestedProperty, useCurrencyFormat } from '../../utilities/parsers'

const defaultColumnProps = {
  sortable: true,
  resizable: true,
  isVisible: true,
}
const defaultColumnProps1 = {
  sortable: false,
  isVisible: false,
  resizable: true,
}
const defaultColumnProps2 = {
  sortable: true,
  isVisible: false,
  resizable: true,
}
const defaultColumnProps3 = {
  isVisible: true,
  sortable: false,
  resizable: true,
}

export const getColumns = ({ onViewAction, statusClassGen }: any) => {
  const format = useCurrencyFormat

  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    switch (isCustom) {
      case 'status':
        return {
          cell: (
            <div className="text-capitalize">
              <span
                className={`statusLabel  ${
                  row.order?.order_status_name
                    ? statusClassGen(row?.order?.order_status_name)
                    : 'Active'
                }`}
              >
                {row?.order?.order_status_name}
              </span>
            </div>
          ),
          toolTip: getNestedProperty(row, key) ?? '',
        }
      case 'amount':
        const propertyValue = getNestedProperty(row, key)
        const numbers =
          typeof propertyValue === 'string' ? parseFloat(propertyValue) : 0

        return {
          cell: format(numbers),
          toolTip: getNestedProperty(row, key) ?? '',
        }
      case 'franchisee':
        return {
          cell: (
            <>
              {row?.franchisee?.franchisee_display_name
                ? row?.franchisee?.franchisee_display_name
                : row?.franchisee?.franchisee_name}
            </>
          ),
        }
      case 'is_advance':
        const is_advance = row.transactions[0]?.is_advance
        return {
          cell: is_advance ? 'Yes' : 'No',
          toolTip: is_advance ? 'Yes' : 'No',
        }
      case 'date':
        return {
          cell: convertDateFormat(getNestedProperty(row, key) as string),
        }
      case 'initiated':
        const user =
          row?.created_by?.first_name + ' ' + row?.created_by?.last_name
        return {
          cell: user,
          toolTip: user,
        }

      default:
        return {
          cell: getNestedProperty(row, key),
          toolTip: getNestedProperty(row, key) ?? '',
        }
    }
  }

  const column = [
    {
      title: 'Payment ID',
      field: 'payment_number',
      ...defaultColumnProps3,
      link: true,
      fixed: true,
      hideLink: (row: any) =>
        row.payment_verification_status?.code === 'not_verified',
      rowClick: (row: any) => onViewAction(row, 'payment'),
    },
    {
      title: 'Transaction ID',
      field: 'name',
      ...defaultColumnProps3,
      renderCell: createRenderCell('transactions.0.transaction_id'),
      customCell: true,
      link: true,
      // sortKey: 'payment_transactions__transaction_id',
      fixed: true,
      rowClick: (row: any) => onViewAction(row, 'transactionId'),
    },

    {
      title: 'Transaction Date',
      field: 'transaction_date',
      renderCell: createRenderCell('transactions.0.transaction_date', 'date'),
      customCell: true,
      ...defaultColumnProps,
      sortKey: 'payment_transactions__transaction_date',
    },
    {
      title: 'Account Name',
      renderCell: createRenderCell('account.business_name'),
      field: 'business_name',
      ...defaultColumnProps,
      customCell: true,
      link: true,
      getPath: (row: any) => onViewAction(row, 'accountName'),
      sortKey: 'order__account_id__business_name',
    },
    {
      title: 'Order ID',
      renderCell: createRenderCell('order.order_number'),
      field: 'order_number',
      ...defaultColumnProps,
      link: true,
      getPath: (row: any) => onViewAction(row, 'orderId'),
      customCell: true,
      sortKey: 'order__order_number',
    },
    {
      title: 'Order Status',
      field: 'order_status',
      renderCell: createRenderCell('order.order_status.name'),
      customCell: true,
      ...defaultColumnProps,
      sortKey: 'payment_transactions__transaction_date',
    },
    {
      title: 'Payment Method',
      renderCell: createRenderCell('payment_method.display_name'),
      field: 'display_name',
      customCell: true,
      sortKey: 'payment_method__name',
      ...defaultColumnProps,
    },
    {
      title: 'Additional Payment Type',
      renderCell: createRenderCell(
        'additional_payment_type.display_name',
        'display_name'
      ),
      field: 'display_name',
      customCell: true,
      ...defaultColumnProps3,
    },
    {
      title: 'Service',
      renderCell: createRenderCell('service.service_name', 'service_name'),
      field: 'service_name',
      customCell: true,
      ...defaultColumnProps3,
    },
    // {
    //   title: 'External Reference ID',
    //   renderCell: createRenderCell('transactions.0.external_reference_id'),
    //   field: 'external_reference_id',
    //   customCell: true,
    //   sortKey: 'payment_transactions__external_reference_id',
    //   ...defaultColumnProps2,
    // },
    {
      title: 'Amount Paid',
      field: 'amount',
      renderCell: createRenderCell('amount', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps,
      sortKey: 'amount',
    },
    {
      title: 'Receipt Number',
      renderCell: createRenderCell('transactions.0.receipt_number'),
      field: 'receipt_number',
      customCell: true,
      link: true,
      getPath: (row: any) => onViewAction(row, 'receiptNumber'),
      hideLink: (row: any) =>
        row.payment_verification_status?.code === 'not_verified',
      sortKey: 'payment_transactions__receipt_number',
      ...defaultColumnProps,
    },
    {
      title: 'Payment Status',
      field: 'payment_status_label',
      sortKey: 'status',
      ...defaultColumnProps,
    },
    {
      title: 'Advance',
      renderCell: createRenderCell('transactions.0.is_advance', 'is_advance'),
      field: 'is_advance',
      customCell: true,
      sortKey: 'payment_transactions__is_advance',
      ...defaultColumnProps,
    },
    {
      title: 'Prof.Fee',
      field: 'professional_fee',
      renderCell: createRenderCell('professional_fee', 'amount'),
      customCell: true,
      align: 'right',
      sortKey: 'professional_fee',
      ...defaultColumnProps2,
    },
    {
      title: 'Vendor Fee',
      field: 'vendor_fee',
      renderCell: createRenderCell('vendor_fee', 'amount'),
      customCell: true,
      align: 'right',
      sortKey: 'vendor_fee',
      ...defaultColumnProps2,
    },
    {
      title: 'Govt. Fee',
      field: 'govt_fee',
      renderCell: createRenderCell('govt_fee', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps2,
      sortKey: 'govt_fee',
    },
    {
      title: 'GST Total',
      field: 'gst',
      renderCell: createRenderCell('gst', 'amount'),
      customCell: true,
      align: 'right',
      sortKey: 'gst',
      ...defaultColumnProps2,
    },
    {
      title: 'Extra Received',
      field: 'extra_received',
      renderCell: createRenderCell('extra_received', 'amount'),
      customCell: true,
      align: 'right',
      sortKey: 'extra_received',
      ...defaultColumnProps2,
    },
    {
      title: 'Payment type',
      field: 'payment_type_label',
      ...defaultColumnProps2,
      sortKey: 'payment_type',
    },
    {
      title: 'Verification Status',
      field: 'payment_type_label',
      renderCell: createRenderCell('payment_verification_status.name'),
      ...defaultColumnProps,
      customCell: true,
      sortKey: 'payment_verification_status__name',
    },
    // {
    //   title: 'Attachment Name',
    //   renderCell: createRenderCell('transaction.0.payment_document'),
    //   field: 'payment_document',
    //   ...defaultColumnProps1,
    //   customCell: true,
    // },
    {
      title: 'Remarks/Message',
      field: 'message',
      ...defaultColumnProps1,
    },
    {
      title: 'Internal Notes',
      field: 'internal_notes',
      ...defaultColumnProps1,
    },
    ...(isAllFranchisee()
      ? [
          {
            title: 'Franchisee',
            field: 'Franchisee',

            renderCell: createRenderCell(
              'franchisee.franchisee_display_name',
              'franchisee'
            ),
            customCell: true,

            ...defaultColumnProps3,
          },
        ]
      : []),
    {
      title: 'Initiated Date',
      renderCell: createRenderCell('transactions.0.datetime_created', 'date'),
      field: 'datetime_created',
      ...defaultColumnProps,
      customCell: true,
      sortKey: 'datetime_created',
    },
    {
      title: 'Intiated By',
      renderCell: createRenderCell('created_by.first_name', 'initiated'),
      ...defaultColumnProps,
      customCell: true,
      sortKey: 'created_by__first_name',
    },
  ]

  return column
}
