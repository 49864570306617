import { z } from 'zod'

const MAX_FILE_SIZE = 50000000

export const ACCEPTED_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf',
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/vnd.ms-excel', // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

const baseAttachmentsSchema = z.object({
  approval_type_name: z.string().refine((val) => val !== '', {
    message: 'Approval type is required',
  }),
  approval_type: z.string().refine((val) => val !== '', {
    message: 'Approval type is required',
  }),
  maxAmount: z.string().optional().nullable(),
  amount: z.union([
    z.string({ invalid_type_error: 'Required' }),
    z.number({ invalid_type_error: 'Required' }),
  ]),
  request_remark: z.string().nullable().optional(),
  attachments: z
    .instanceof(File, { message: 'Attachment required' })
    .refine((files: File) => files.size <= MAX_FILE_SIZE, {
      message: 'Maximum size 5mb',
    })
    .refine((files: File) => ACCEPTED_IMAGE_TYPES.includes(files?.type), {
      message: 'File type not allowed',
    })
    .array()
    .nullable()
    .optional(),
  bizpole_payable_amount: z.string().optional().nullable(),
})

export const attachmentsSchema = baseAttachmentsSchema.superRefine(
  (data, ctx) => {
    const amount =
      typeof data.amount === 'string' ? parseFloat(data.amount) : data.amount
    const maxAmount =
      typeof data.maxAmount === 'string'
        ? parseFloat(data.maxAmount)
        : data.maxAmount

    if (maxAmount && maxAmount > 0 && amount <= 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Enter a valid amount',
        path: ['amount'],
      })
    }

    if (!maxAmount) {
      if (amount) {
        if (!amount || amount === 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Amount should be greater than 0',
            path: ['amount'],
          })
        } else if (isNaN(Number(amount))) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Enter a valid amount',
            path: ['amount'],
          })
        } else if (amount <= 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Amount should be greater than 0',
            path: ['amount'],
          })
        }
      }
    } else {
      if (amount) {
        if (maxAmount > 0 && amount <= 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Enter a valid amount',
            path: ['amount'],
          })
        } else if (amount > maxAmount) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Maximum payable amount is ${maxAmount}`,
            path: ['amount'],
          })
        }
      } else if (isNaN(Number(amount))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Enter a valid amount',
          path: ['amount'],
        })
      }
    }
  }
)

export const specialFeeSchem = baseAttachmentsSchema.extend({
  amount: z.union([z.string(), z.number()]).optional().nullable(),
})

export type AttachmentsSchema = z.infer<typeof attachmentsSchema>
export type SpecialFeeAttachmentsSchema = z.infer<typeof specialFeeSchem>
