import Icons from '../../../../components/common/icons'
import { numberFormat } from '../../../../utilities/parsers'

type Props = {
  data: any
}
const PricingInformation = ({ data }: Props) => {
  return (
    <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icons name="cards" />
          <h4 className=" font-bold text-primaryText">Pricing Information</h4>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-5">
        <div className=" col-span-2 xl:col-span-1 bg-white border border-formBorder rounded-xl p-5 flex flex-col gap-4">
          <p className=" text-base text-secondary font-bold">Service Pricing</p>
          <div className="flex items-center justify-between gap-2">
            <p className=" text-sm text-secondary">Professional Fee</p>
            <p className=" text-sm text-secondary font-bold">
              {numberFormat(data?.professional_fee) ?? 0}
            </p>
          </div>
          <div className="flex items-center justify-between gap-2">
            <p className=" text-sm text-secondary">Vendor Fee</p>
            <p className=" text-sm text-secondary font-bold">
              {numberFormat(data?.vendor_fee) ?? 0}
            </p>
          </div>
          <div className="flex items-center justify-between gap-2">
            <p className=" text-sm text-secondary">Govt. Fee</p>
            <p className=" text-sm text-secondary font-bold">
              {numberFormat(data?.govt_fee) ?? 0}
            </p>
          </div>
          <div className="flex items-center justify-between gap-2">
            <p className=" text-sm text-secondary">GST Amount</p>
            <p className=" text-sm text-secondary font-bold">
              {numberFormat(data?.gst_amount) ?? 0}
            </p>
          </div>
          <div className="flex items-center justify-between gap-2">
            <p className=" text-sm text-secondary">Discount Amount</p>
            <p className=" text-sm text-secondary font-bold">
              {numberFormat(data?.discount_amount) ?? 0}
            </p>
          </div>
          <div className="flex items-center justify-between gap-2">
            <p className=" text-sm text-secondary">Rounding</p>
            <p className=" text-sm text-secondary font-bold">
              {numberFormat(data?.price_adjustment) ?? 0}
            </p>
          </div>
          <div className="w-full h-px bg-formBorder"></div>
          <div className="flex items-center justify-between gap-2">
            <p className=" text-sm text-primaryText font-bold">Total Amount</p>
            <p className=" text-sm text-primaryText font-bold">
              {numberFormat(data?.total_price) ?? 0}
            </p>
          </div>
        </div>
        {data?.allocation_details?.length > 0 ? (
          <div className=" col-span-2 xl:col-span-1 bg-cardWrapperBg  rounded-xl p-5 flex flex-col gap-4 h-fit">
            <p className=" text-base text-secondary font-bold">
              Payment Allocation Summary
            </p>
            <div className="w-full h-px bg-formBorder"></div>

            <div className="flex items-center justify-between gap-2">
              <p className="text-sm text-secondary">Allocated Govt. Fee</p>
              <p className="text-sm text-secondary font-bold">
                {numberFormat(data?.allocated_govt_fee) ?? 0}
              </p>
            </div>
            <div className="flex items-center justify-between gap-2">
              <p className="text-sm text-secondary">Requestable Govt. Fee</p>
              <p className="text-sm text-secondary font-bold">
                {numberFormat(data?.requestable_govt_fee) ?? 0}
              </p>
            </div>

            <div className="flex items-center justify-between gap-2">
              <p className="text-sm text-secondary">Allocated Vendor Fee</p>
              <p className="text-sm text-secondary font-bold">
                {numberFormat(data?.allocated_vendor_fee) ?? 0}
              </p>
            </div>
            <div className="flex items-center justify-between gap-2">
              <p className="text-sm text-secondary">Requestable Vendor Fee</p>
              <p className="text-sm text-secondary font-bold">
                {numberFormat(data?.requestable_vendor_fee) ?? 0}
              </p>
            </div>

            <div className="w-full h-px bg-formBorder"></div>

            {/* {data?.allocation_details?.map((item: any) => (
              <>
                <div className="flex items-center justify-between gap-2">
                  <p className="text-sm text-secondary">
                    On {moment(item.datetime_created).format('DD/MM/YYYY')}
                  </p>
                  <p className="text-sm text-secondary font-bold">
                    {numberFormat(item?.allocated_amount) ?? 0}
                  </p>
                </div>
              </>
            ))} */}

            {/* <div className="w-full h-px bg-formBorder"></div> */}
            <div className="flex items-center justify-between gap-2">
              <p className=" text-sm text-primaryText font-bold">
                Total Allocated
              </p>
              <p className=" text-sm text-primaryText font-bold">
                {data?.service_allocated_amount !== null
                  ? numberFormat(data?.service_allocated_amount)
                  : 0}
              </p>
            </div>
            <div className="w-full h-px bg-formBorder"></div>
            <div className="flex items-center justify-between gap-2">
              <p className=" text-sm text-primaryText font-bold">
                Pending Allocation
              </p>
              <p className=" text-sm text-primaryText font-bold">
                {data?.balance_amount_to_allocate != null
                  ? numberFormat(data?.balance_amount_to_allocate)
                  : 0}
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default PricingInformation
