import { useMutation } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { getData, patchData, postFormData } from '../../../../apis/api.helpers'
import apiUrl from '../../../../apis/api.url'
import { QueryParams } from '../../../../common/types'
import { useSnackbarManager } from '../../../../components/common/snackbar'
import {
  getErrorMessage,
  parseQueryParams,
} from '../../../../utilities/parsers'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}

export const getApprovalReqType = (data?: any) => {
  const params = {
    ...data,
  }
  const response = getData(
    `${apiUrl.APPROVAL_TYPES}${parseQueryParams(params)}`
  )
  return response
}
export const getApprovalReviewrs = (id: string, data?: any) => {
  const params = {
    stakeholder_id: id,
    ...data,
  }
  const response = getData(
    `${apiUrl.APPROVAL_REVIEWRS}${parseQueryParams(params)}`
  )
  return response
}

export const useCreateActivityRequests = (
  handleSubmission: (data: any) => void,
  service_id: any,
  order_id: any
) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(
    (datas: any) => createActivityRequests(datas, service_id, order_id),
    {
      onSuccess: (res: any) => {
        handleSubmission(res.data)
        enqueueSnackbar('Request Created successfully', { variant: 'success' })
      },

      onError: (error: any) => {
        enqueueSnackbar(getErrorMessage(error.response.data.error), {
          variant: 'error',
        })
      },
    }
  )
}

export const createActivityRequests = (
  data: any,
  service_id: any,
  order_id: any
) => {
  return postFormData(
    `${apiUrl.SERVICE_PAYMENT_REQUEST}${order_id}/items/${service_id}/service-payment-request`,
    data
  )
}

const getServicePaymentRequestDetails = async (
  order_id: string,
  service_id: string,
  input: QueryParams
) => {
  const url = buildUrlWithParams(
    `${apiUrl.SERVICE_PAYMENT_REQUEST}${order_id}/items/${service_id}/service-payment-request`,
    {
      ...input,
    }
  )
  const response = await getData(url)
  return response
}

export const useServicePaymentRequestDetails = (
  order_id: string,
  service_id: string,
  input: any
) => {
  return useQuery(
    ['service_payments_request_list', input],
    () => getServicePaymentRequestDetails(order_id, service_id, input),
    {
      refetchOnWindowFocus: false,
      //staleTime: 50000,
    }
  )
}

export const addServicePayout = (input: {
  orderId: string
  serviceId: string
  paymentRequestId: string
  data: any
}) => {
  return patchData(
    `${apiUrl.SERVICE_PAYMENT_REQUEST}/${input.orderId}/items/${input.serviceId}/service-payment-request/${input.paymentRequestId}/mark_as_paid`,
    input.data
  )
}
