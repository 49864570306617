import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

const PaymentReceipt = lazy(() => import('../components/app/payment/receipt'))
const AccountAddresses = lazy(
  () => import('../pages/accounts/details/addresses')
)
const AccountAgents = lazy(() => import('../pages/accounts/details/agents'))
const AccountAttachments = lazy(
  () => import('../pages/accounts/details/attachments')
)
const AccountBasicInfo = lazy(
  () => import('../pages/accounts/details/basic-info')
)
const AccountContacts = lazy(() => import('../pages/accounts/details/contacts'))
const AccountEventLogs = lazy(
  () => import('../pages/accounts/details/eventlogs')
)
const AccountNotes = lazy(() => import('../pages/accounts/details/notes'))
const AccountOrders = lazy(
  () => import('../pages/accounts/details/orders/index')
)
// const Service = lazy(() => import('../pages/service-management/services'))
import { router_config } from '../configs/route.config'

import AccountTask from '../pages/accounts/details/task'
import ActivityFiles from '../pages/activities/details/files'
import ActivityLogs from '../pages/activities/details/log'
import ActivityNotes from '../pages/activities/details/notes'
import ActivityRequests from '../pages/activities/details/requests'
import ActivitySummary from '../pages/activities/details/summary'
import ActivityTasks from '../pages/activities/details/tasks'
import ContactAccounts from '../pages/contacts/details/accounts'
import ContactAttachments from '../pages/contacts/details/attachments'
import ContactEventLogs from '../pages/contacts/details/eventlogs'
import ContactNotes from '../pages/contacts/details/notes'
import ContactSummary from '../pages/contacts/details/summary'
import ContactTask from '../pages/contacts/details/task'

import { MODULES } from '../configs/scopes.config'
import OrderFiles from '../pages/order/details/files'
import OrderLogs from '../pages/order/details/log'
import OrderNote from '../pages/order/details/notes'
import OrderServices from '../pages/order/details/services'
import OrderSummary from '../pages/order/details/summary'
import OrderTasks from '../pages/order/details/tasks'
import ServiceActivityProgress from '../pages/service/details/activityProgress'
import ServiceFiles from '../pages/service/details/files/index'
import ServiceLog from '../pages/service/details/log/index'
import ServiceNotes from '../pages/service/details/notes/index'
import ServicePaymentApproval from '../pages/service/details/paymentRequest/index'
import ServicePayments from '../pages/service/details/payments/index'
import ServiceSummary from '../pages/service/details/summary/index'
import ServiceTasks from '../pages/service/details/tasks/index'
import ChildRoute from './components/childRoute'
import GuestRoute from './components/guestRoute'
import UserRoute from './components/userRoute'
import OrderPaymentReceipt from '../components/app/payment/receipt/orderReciept'
import { checkPermissionAny } from '../configs/permissionGate'
import OrderPayments from '../pages/order/details/payments'
const Login = lazy(() => import('../pages/userManagement/login'))
const ForgetPassword = lazy(
  () => import('../pages/userManagement/forgetPassword')
)

// Dashboard

const Dashboard = lazy(() => import('../pages/dashboard/dashboard'))
const Accounts = lazy(() => import('../pages/accounts'))
const AccountDetails = lazy(() => import('../pages/accounts/details'))

// Contacts

const ContactDetails = lazy(() => import('../pages/contacts/details'))

// Employees

const Employees = lazy(() => import('../pages/employees'))
const EmployeeDetails = lazy(() => import('../pages/employees/details/'))
const EmployeeBasicInfo = lazy(
  () => import('../pages/employees/details/basicInfo')
)
const EmployeeTeamInfo = lazy(
  () => import('../pages/employees/details/teamInfo')
)
// Service orders

// Service orders - orders

const OrderList = lazy(() => import('../pages/order'))
const OrderDetails = lazy(() => import('../pages/order/details'))

// Service orders - service

const ServiceList = lazy(() => import('../pages/service'))

// Teams

const Teams = lazy(() => import('../pages/teams'))

const TeamDetails = lazy(() => import('../pages/teams/details'))
const TeamInfo = lazy(() => import('../pages/teams/details/basicInfo'))
const TeamMembers = lazy(() => import('../pages/teams/details/members'))
const TeamServices = lazy(() => import('../pages/teams/details/services'))

// Roles

const Roles = lazy(() => import('../pages/roles'))
const RoleDetails = lazy(() => import('../pages/roles/details'))
const RoleDetailsBasics = lazy(() => import('../pages/roles/details/summary'))
const RoleDetailsPermissions = lazy(
  () => import('../pages/roles/details/permissions')
)

// Vendor Service

const VendorService = lazy(() => import('../pages/vendor-services'))
const VendorServiceDetails = lazy(
  () => import('../pages/vendor-services/details')
)
const VendorServiceDetailsBasics = lazy(
  () => import('../pages/vendor-services/details/summary')
)
const VendorServiceDetailsTeam = lazy(
  () => import('../pages/vendor-services/details/team')
)

// Services
const ServiceDetails = lazy(() => import('../pages/service/details'))

// My Tasks

const MyTasks = lazy(() => import('../pages/my-tasks'))
const Notifications = lazy(() => import('../pages/notifications'))

// My Approvals

const MyApprovals = lazy(() => import('../pages/my-approvals'))

// My Payment Approvals

const MyPaymentApprovals = lazy(() => import('../pages/my-payment-approvals'))

// Pay Out
const PayOuts = lazy(() => import('../pages/pay-out'))

// Export
const ExportList = lazy(() => import('../pages/exports'))
// Reports

const Reports = lazy(() => import('../pages/reports'))
const CREPerformanceSummaryComponent = lazy(
  () => import('../pages/reports/cre-performance-summary')
)
const TeamPerformanceSummaryComponent = lazy(
  () => import('../pages/reports/team-performance-summary')
)
const CREServiceStatusComponent = lazy(
  () => import('../pages/reports/cre-service-status')
)

const Activities = lazy(() => import('../pages/activities'))
const ActivityDetails = lazy(() => import('../pages/activities/details'))
const ShareDeliverables = lazy(
  () => import('../pages/service/shareDeliverables/index')
)
const ShareDeliverableDetails = lazy(
  () => import('../pages/service/shareDeliverables/deliverables')
)
const DataCollection = lazy(
  () => import('../pages/service/details/data-collection/index')
)
// import Profile from '../pages/profile'
import ServiceGovtFees from '../pages/service/details/govtFees'
import Stakeholder from '../pages/stakeholders'
import StakeholderDetails from '../pages/stakeholders/details'
import StakeholderDetailsSummary from '../pages/stakeholders/details/summary'
import StakeholderDetailTeams from '../pages/stakeholders/details/teams'
import ForceChangePassword from '../pages/userManagement/changePassword'
import ResetPassword from '../pages/userManagement/resetPassword'
import PayoutListing from '../pages/payouts'
import AdditionalStatusLog from '../pages/service/details/additionalStatusLog'
import AdditionalGovtPayments from '../pages/service/details/additionalGovtPayments'
import Payments from '../pages/payments'

const routes = [
  { slug: 'DASHBOARD', component: <Dashboard /> },
  {
    slug: 'ACCOUNTS',
    component: <Accounts />,
    isPrimary: true,
  },

  {
    slug: 'ACCOUNT_DETAILS',
    component: <AccountDetails />,
    children: [
      {
        slug: 'ACCOUNT_DETAILS_BASIC_INFO',
        component: <AccountBasicInfo />,
      },
      {
        slug: 'ACCOUNT_DETAILS_CONTACTS',
        component: <AccountContacts />,
      },
      {
        slug: 'ACCOUNT_DETAILS_EVENT_LOGS',
        component: <AccountEventLogs />,
      },
      {
        slug: 'ACCOUNT_TASKS',
        component: <AccountTask />,
      },

      {
        slug: 'ACCOUNT_FILES_AND_ATTACHMENTS',
        component: <AccountAttachments />,
      },
      {
        slug: 'ACCOUNT_DETAILS_AGENTS',
        component: <AccountAgents />,
      },
      {
        slug: 'ACCOUNT_DETAILS_ORDERS',
        component: <AccountOrders />,
      },
      {
        slug: 'ACCOUNT_DETAILS_ADDRESSES',
        component: <AccountAddresses />,
      },
      {
        slug: 'ACCOUNT_DETAILS_NOTES',
        component: <AccountNotes />,
      },
    ],
  },
  {
    slug: 'PAYOUTS',
    component: <PayOuts />,
  },
  {
    slug: 'EXPORTS',
    component: <ExportList />,
  },
  {
    slug: 'ACTIVITIES',
    component: <Activities />,
    isPrimary: true,
  },
  {
    slug: 'ACTIVITY_DETAILS',
    component: <ActivityDetails />,
    children: [
      {
        slug: 'ACTIVITY_DETAILS_SUMMARY',
        component: <ActivitySummary />,
      },
      {
        slug: 'ACTIVITY_DETAILS_NOTES',
        component: <ActivityNotes />,
      },
      {
        slug: 'ACTIVITY_DETAILS_REQUESTS',
        component: <ActivityRequests />,
      },
      {
        slug: 'ACTIVITY_DETAILS_FILES',
        component: <ActivityFiles />,
      },
      {
        slug: 'ACTIVITY_DETAILS_TASKS',
        component: <ActivityTasks />,
      },
      {
        slug: 'ACTIVITY_DETAILS_LOGS',
        component: <ActivityLogs />,
      },
    ],
  },

  {
    slug: 'CONTACT_DETAILS',
    component: <ContactDetails />,
    children: [
      {
        slug: 'CONTACT_DETAILS_SUMMARY',
        component: <ContactSummary />,
      },
      {
        slug: 'CONTACT_DETAILS_ACCOUNTS',
        component: <ContactAccounts />,
      },
      {
        slug: 'CONTACT_DETAILS_EVENT_LOGS',
        component: <ContactEventLogs />,
      },
      {
        slug: 'CONTACT_TASKS',
        component: <ContactTask />,
      },

      {
        slug: 'CONTACT_FILES_AND_ATTACHMENTS',
        component: <ContactAttachments />,
      },
      {
        slug: 'CONTACT_DETAILS_NOTES',
        component: <ContactNotes />,
      },
    ],
  },

  {
    slug: 'MY_TASKS',
    component: <MyTasks />,
  },
  {
    slug: 'NOTIFICATIONS',
    component: <Notifications />,
  },
  {
    slug: 'MY_TASKS_VIEW',
    component: <MyTasks detail={true} />,
  },
  {
    slug: 'MY_PAYMENT_APPROVALS',
    component: <MyPaymentApprovals />,
  },
  {
    slug: 'MY_PAYMENT_APPROVAL_DETAILS',
    component: <MyPaymentApprovals />,
  },

  {
    slug: 'MY_APPROVALS',
    component: <MyApprovals />,
  },
  {
    slug: 'MY_APPROVAL_DETAILS',
    component: <MyApprovals />,
  },
  {
    slug: 'REPORTS',
    component: <Reports />,
    isPrimary: true,
  },
  {
    slug: 'PAYMENTS',
    component: <Payments />,
  },
  {
    slug: 'REPORT_CRE_PERFORMANCE_SUMMARY',
    component: <CREPerformanceSummaryComponent />,
  },
  {
    slug: 'REPORT_TEAM_PERFORMANCE_SUMMARY',
    component: <TeamPerformanceSummaryComponent />,
  },
  {
    slug: 'REPORT_CRE_SERVICE_STATUS',
    component: <CREServiceStatusComponent />,
  },
  {
    slug: 'SERVICE_AND_ORDERS_BY_ORDERS',
    component: <OrderList />,
  },

  {
    slug: 'SERVICE_AND_ORDERS_BY_ORDERS_DETAILS',
    component: <OrderDetails />,
    children: [
      {
        slug: 'SERVICE_AND_ORDERS_BY_ORDERS_SUMMARY',
        component: <OrderSummary />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_ORDERS_SERVICES',
        component: <OrderServices />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_ORDERS_PAYMENTS',
        component: <OrderPayments />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_ORDERS_NOTES',
        component: <OrderNote />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_ORDERS_FILES',
        component: <OrderFiles />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_ORDERS_TASKS',
        component: <OrderTasks />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_ORDERS_LOGS',
        component: <OrderLogs />,
      },
    ],
  },
  {
    slug: 'SERVICE_AND_ORDERS_BY_ORDERS_PAYMENTS_RECEIPT',
    component: <OrderPaymentReceipt />,
  },
  {
    slug: 'PAYMENTS_RECEIPT',
    component: <PaymentReceipt />,
  },

  {
    slug: 'SERVICE_AND_ORDERS_BY_SERVICE',
    primary: true,
    component: <ServiceList />,
  },
  {
    slug: 'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    component: <ServiceDetails />,
    children: [
      {
        slug: 'SERVICE_AND_ORDERS_BY_SERVICE_SUMMARY',
        component: <ServiceSummary />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_SERVICE_ACTIVITY_PROGRESS',
        component: <ServiceActivityProgress />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_SERVICE_PAYMENTS',
        component: <ServicePayments />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_SERVICE_NOTES',
        component: <ServiceNotes />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_SERVICE_FILES',
        component: <ServiceFiles />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_SERVICE_LOGS',
        component: <ServiceLog />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_SERVICE_TASKS',
        component: <ServiceTasks />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_SERVICE_PAYMENT_APPROVAL',
        component: <ServicePaymentApproval />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_SERVICE_ADDITIONAL_GOVT_PAYMENTS',
        component: <AdditionalGovtPayments />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_SERVICE_GOVT_FEES',
        component: <ServiceGovtFees />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_DATAFORMS',
        component: <DataCollection />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_SERVICE_ADDITIONAL_STATUS_LOG',
        component: <AdditionalStatusLog />,
      },
    ],
  },
  {
    slug: 'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEES',
    isPrimary: true,
    component: <Employees />,
  },
  {
    slug: 'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_DETAILS',
    component: <EmployeeDetails />,
    children: [
      {
        slug: 'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_BASIC_INFO',
        component: <EmployeeBasicInfo />,
      },
      {
        slug: 'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_TEAM_INFO',
        component: <EmployeeTeamInfo />,
      },
    ],
  },
  {
    slug: 'SYSTEM_SETTINGS_ORGANIZATION_TEAMS',
    component: <Teams />,
  },
  {
    slug: 'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_DETAILS',
    component: <TeamDetails />,
    children: [
      {
        slug: 'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_BASIC_INFO',
        component: <TeamInfo />,
      },
      {
        slug: 'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_MEMBER',
        component: <TeamMembers />,
      },
      {
        slug: 'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_SERVICE',
        component: <TeamServices />,
      },
    ],
  },
  {
    slug: 'SYSTEM_SETTINGS_ORGANIZATION_ROLES',
    component: <Roles />,
  },
  {
    slug: 'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS',
    component: <Stakeholder />,
  },
  {
    slug: 'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS',
    component: <StakeholderDetails />,
    children: [
      {
        slug: 'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_SUMMARY',
        component: <StakeholderDetailsSummary />,
      },
      {
        slug: 'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_TEAMS',
        component: <StakeholderDetailTeams />,
      },
    ],
  },
  {
    slug: 'PAYOUTS',
    component: <PayoutListing />,
  },
  {
    slug: 'SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS',
    component: <RoleDetails />,
    children: [
      {
        slug: 'SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS_SUMMARY',
        component: <RoleDetailsBasics />,
      },
      {
        slug: 'SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS_PERMISSIONS',
        component: <RoleDetailsPermissions />,
      },
    ],
  },
  // {
  //   slug: 'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES',
  //   isPrimary: true,
  //   component: <Service />,
  // },
  {
    slug: 'SYSTEM_SETTINGS_VENDOR_SERVICE',
    component: <VendorService />,
  },
  {
    slug: 'SYSTEM_SETTINGS_VENDOR_SERVICE_DETAILS',
    component: <VendorServiceDetails />,
    children: [
      {
        slug: 'SYSTEM_SETTINGS_VENDOR_SERVICE_DETAILS_SUMMARY',
        component: <VendorServiceDetailsBasics />,
      },
      {
        slug: 'SYSTEM_SETTINGS_VENDOR_SERVICE_DETAILS_TEAM',
        component: <VendorServiceDetailsTeam />,
      },
    ],
  },

  {
    isAuthRoute: true,
    slug: 'LOGIN',
    component: <Login />,
  },
  {
    isAuthRoute: true,
    slug: 'CHANGE_PASSWORD',
    component: <ForceChangePassword />,
  },
  {
    isAuthRoute: true,
    slug: 'FORGET_PASSWORD',
    component: <ForgetPassword />,
  },
  {
    isAuthRoute: true,
    slug: 'RESET_PASSWORD',
    component: <ResetPassword />,
  },
  // {
  //   slug: 'USER_PROFILE',
  //   component: <Profile />,
  // },
  {
    slug: 'SHARE_DELIVERABLES',
    component: <ShareDeliverables />,
    children: [
      {
        slug: 'SHARE_DELIVERABLES_SUMMARY',
        component: <ServiceSummary />,
      },
      {
        slug: 'SHARE_DELIVERABLES_DETAILS',
        component: <ShareDeliverableDetails />,
      },
    ],
  },
]
export default function MainRoutes() {
  return (
    <>
      <Routes>
        {routes.map((route) => (
          <>
            {route.isAuthRoute ? (
              <Route
                element={<GuestRoute>{route.component}</GuestRoute>}
                path={router_config[route.slug].path}
                key={router_config[route.slug].path}
              />
            ) : (
              <>
                <Route
                  element={
                    <UserRoute
                      slug_key={route.slug}
                      hasChild={
                        route?.isPrimary
                          ? false
                          : Boolean(route?.children?.length)
                      }
                    >
                      {route.component}
                    </UserRoute>
                  }
                  path={router_config[route.slug].path}
                  key={router_config[route.slug].path}
                >
                  {route.children?.length && (
                    <>
                      {route.children.map((child) => (
                        <Route
                          element={
                            <ChildRoute slug_key={child.slug}>
                              {child.component}
                            </ChildRoute>
                          }
                          path={router_config[child.slug].path}
                          key={router_config[child.slug].path}
                        />
                      ))}
                    </>
                  )}
                </Route>
              </>
            )}
          </>
        ))}

        <Route
          element={
            <UserRoute
              slug_key={
                checkPermissionAny(MODULES.dashboard) ? 'DASHBOARD' : 'MY_TASKS'
              }
            >
              {checkPermissionAny(MODULES.dashboard) ? (
                <Dashboard />
              ) : (
                <MyTasks />
              )}
            </UserRoute>
          }
          key="*"
          path="*"
        />
      </Routes>
    </>
  )
}
