import moment from 'moment'

import {
  getNestedProperty,
  useCurrencyFormat,
} from '../../../../utilities/parsers'

const defaultColumnProps3 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({ onViewAction }: any) => {
  const createRenderCell =
    (key: string, customData = '') =>
    (row: any) => {
      const format = useCurrencyFormat
      switch (customData) {
        case 'is_advance':
          const advance: any = getNestedProperty(row, key)
          return {
            cell: advance ? 'Yes' : 'No',
            toolTip: advance ? 'Yes' : 'No',
          }
        case 'created_by':
          const created_by: any = getNestedProperty(row, key)
          return {
            cell: created_by?.first_name + ' ' + created_by.last_name,
          }

        case 'transaction':
          return {
            cell: (
              <>
                {row?.transactions[0]?.transaction_date
                  ? moment(row?.transactions[0]?.transaction_date).format(
                      'DD-MM-YYYY'
                    )
                  : '--'}
              </>
            ),
          }
        case 'initiated':
          return {
            cell: (
              <>
                {/* {row?.datetime_created
                  ? moment(row?.datetime_created).format('DD-MM-YYYY')
                  : '--'} */}
                {row?.datetime_created}
              </>
            ),
          }
        case 'amount':
          const propertyValue: any = getNestedProperty(row, key)
          const numbers =
            typeof propertyValue === 'string'
              ? parseFloat(propertyValue)
              : propertyValue
          return {
            cell: format(numbers),
            toolTip: getNestedProperty(row, key) ?? '',
          }
        case 'payment_document':
          const document: any = getNestedProperty(row, key)
          return {
            cell: document ? (
              <a target={'_blank'} rel="noreferrer" href={document.url}>
                file
              </a>
            ) : (
              ''
            ),
          }
        default:
          return {
            cell: getNestedProperty(row, key),
            toolTip: getNestedProperty(row, key),
          }
      }
    }

  const column = [
    {
      title: 'Payment No',
      field: 'payment_number',
      ...defaultColumnProps3,
      link: true,
      fixed: true,
      hideLink: (row: any) =>
        row.payment_verification_status?.code === 'not_verified',
      rowClick: (row: any) => onViewAction(row, 'payment'),
    },
    {
      title: 'Txn Date',
      renderCell: createRenderCell(
        'transactions.0.transaction_date',
        'transaction'
      ),
      customCell: true,
      ...defaultColumnProps3,
      fixed: true,
      colWidth: 180,
    },
    {
      title: 'Txn ID',
      renderCell: createRenderCell('transactions.0.transaction_id'),
      customCell: true,
      link: true,
      ...defaultColumnProps3,
      colWidth: 180,
      rowClick: (row: any) => onViewAction(row, 'transactionId'),
    },
    {
      title: 'Account Name',
      getPath: (row: any) => onViewAction(row, 'account'),
      link: true,
      renderCell: createRenderCell('account.business_name'),
      ...defaultColumnProps3,
    },
    {
      title: 'Account CRE',
      renderCell: createRenderCell('account.account_cre'),
      customCell: true,
      ...defaultColumnProps3,
      colWidth: 180,
    },
    {
      title: 'Order ID',
      getPath: (row: any) => onViewAction(row, 'order'),
      link: true,
      renderCell: createRenderCell('order.order_number'),
      customCell: true,
      ...defaultColumnProps3,
      colWidth: 200,
    },
    {
      title: 'Mode',
      renderCell: createRenderCell('payment_method.display_name'),
      customCell: true,
      ...defaultColumnProps3,
      colWidth: 100,
    },
    {
      title: 'Amount Paid',
      field: 'amount',
      ...defaultColumnProps3,
      renderCell: createRenderCell('amount', 'amount'),
      colWidth: 100,
      customCell: true,
      align: 'right',
    },
    {
      title: 'Receipt ID',
      renderCell: createRenderCell('transactions.0.receipt_number'),
      customCell: true,
      ...defaultColumnProps3,
      colWidth: 100,
    },
    {
      title: 'Payment Status',
      field: 'payment_status_label',
      ...defaultColumnProps3,
      colWidth: 150,
    },
    {
      title: 'Verification Status',
      renderCell: createRenderCell('payment_verification_status.name'),
      customCell: true,
      ...defaultColumnProps3,
      colWidth: 150,
    },
    {
      title: 'Advance',
      ...defaultColumnProps3,
      renderCell: createRenderCell('transactions.0.is_advance', 'is_advance'),
      customCell: true,
      colWidth: 100,
    },
    {
      title: 'Document',
      ...defaultColumnProps3,
      renderCell: createRenderCell(
        'transactions.0.payment_document',
        'payment_document'
      ),
      customCell: true,
      colWidth: 100,
    },
    {
      title: 'Initiated By',
      ...defaultColumnProps3,
      renderCell: createRenderCell('created_by', 'created_by'),
      customCell: true,
      colWidth: 150,
    },
    {
      title: 'Initiated On ',
      field: 'datetime_created',
      renderCell: createRenderCell('datetime_created', 'initiated'),
      customCell: true,

      ...defaultColumnProps3,
      colWidth: 180,
    },
  ]

  return column
}
