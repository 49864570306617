import { zodResolver } from '@hookform/resolvers/zod'
import { QbsTable } from 'qbs-react-grid'
import { useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'

import InfoBox from '../../../../../components/app/alertBox/infoBox'
import FormBuilder from '../../../../../components/app/formBuilder/index'
import { DialogModal } from '../../../../../components/common'
import CustomDrawer from '../../../../../components/common/drawer'
import TextField from '../../../../../components/common/inputs/TextField'
import { useSnackbarManager } from '../../../../../components/common/snackbar'
import { useAuthStore } from '../../../../../store/authStore'
import { useMasterData } from '../../../../../store/masterDataStore'
import {
  getErrorMessage,
  useCurrencyFormat,
} from '../../../../../utilities/parsers'
import {
  getVendorPaymentGateway,
  sendLinkConfirm,
  useAddPayment,
} from '../../../api'
import { getColumns } from './column'
import { paymentSchema, sendPaymentSchema } from './schema'

type Props = {
  isDrawerOpen: boolean
  handleClose: (f: boolean) => void
  parentParams?: { id?: string; name?: string }
  handleCallback?: () => void
  ServiceItems?: {
    id: string
    name: string
    unique_id: string
    pending_allocation_amount: number
  }[]
}

export default function SendLink({
  isDrawerOpen,
  handleClose,
  parentParams,
  handleCallback,
  ServiceItems,
}: Props) {
  const { masterData } = useMasterData()
  const { userData } = useAuthStore()
  const [vendorDefaultGateway, setVendorDefaultGateway] = useState<any>({})
  const [serviceData, setServiceData] = useState<any>([])
  const [schema, setSchema] = useState<any>()
  const [amountErrors, setAmountErrors] = useState<{
    [key: string]: string
  } | null>(null)
  const [requiredError, setRequiredError] = useState<boolean>(false)
  const [confirmPopup, setConfirmPopup] = useState(false)
  const [tempData, setTempData] = useState<any>()
  const [confirmResponse, setConfirmResponse] = useState<any>()
  const [serviceDistributions, setServiceDistributions] = useState<any>()

  const { enqueueSnackbar } = useSnackbarManager()

  const hasFetchedData = useRef(false)
  useEffect(() => {
    if (!hasFetchedData.current) {
      if (userData?.vendor) {
        getVendorPaymentGateway(userData?.vendor).then((res) => {
          setVendorDefaultGateway({
            ...res?.data?.default_payment_gateway,
          })
        })
      }
      hasFetchedData.current = true
    }
  }, [])
  useEffect(() => {
    methods.reset({
      payment_method_name: vendorDefaultGateway?.display_name,
      payment_method: vendorDefaultGateway?.id,
      is_advance: 'false',
    })
  }, [vendorDefaultGateway, isDrawerOpen])
  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    spacing = 4,
    type = 'text',
    unique_id = '',
    max_amount = 0
  ) => ({
    name,
    label,
    id: name,
    type: type,
    placeholder,
    ...(required ? { required: true } : {}),
    spacing: spacing,
    unique_id,
    max_amount,
  })
  const formBuilderProps = [
    {
      ...textField(
        'amount',
        'Amount',
        'Amount',
        ServiceItems?.length === 0,
        8,
        'number'
      ),
      disabled: true,
    },
    {
      name: 'payment_method_name',
      label: 'Payment Method',
      required: false,
      data: masterData?.payment_methods,
      async: false,
      id: 'payment_method',
      paginationEnabled: false,
      descId: 'id',
      initialLoad: true,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Enter Payment Method',
      disabled: true,
    },
    {
      ...textField('message', 'Message to Customer', 'Enter Message', false),
      type: 'textarea',
      disabled: false,
    },
    {
      ...textField(
        'internal_notes',
        'Internal Notes',
        'Enter internal notes',
        false
      ),
      type: 'textarea',
      disabled: false,
    },
  ]
  const methods = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues: {
      is_advance: 'false',
    },
    reValidateMode: 'onChange',
  })

  const handleSubmission = () => {
    setConfirmPopup(false)
    setTempData(undefined)
    setConfirmResponse(undefined)
    methods.reset()
    handleClearAndClose()
  }
  const { mutate, data, isLoading } = useAddPayment(handleSubmission)

  const submitData = () => {
    const formData = new FormData()
    const data = tempData

    Object.keys(data)?.forEach((item: string) => {
      if (item === 'is_advance') {
        formData.append('is_advance', data.is_advance ?? false)
      } else {
        formData.append(item, data[item] ?? '')
      }
    })

    formData.append(
      'service_distributions',
      JSON.stringify(serviceDistributions)
    )
    formData.append('payment_type', 'link')
    mutate({
      input: formData,
      id: parentParams?.id,
    })
  }

  const onSubmit = (data: any) => {
    if (
      methods.watch('amount') === undefined ||
      methods.watch('amount') === 0
    ) {
      const newErrors: { [key: string]: string } = {}
      ServiceItems &&
        ServiceItems.forEach((item: any) => {
          newErrors[item.id] = 'Required'
        })
      newErrors && setRequiredError(true)
      // setAmountErrors(newErrors)
      return
    }
    if (amountErrors && Object.keys(amountErrors).length > 0) return
    try {
      const formData = new FormData()
      const serviceDistributionsObject = serviceData.reduce(
        (acc: any, item: any) => {
          acc[item.id] = item.service_amount
          return acc
        },
        {}
      )

      if (Object.keys(serviceDistributionsObject).length > 0) {
        formData.append(
          'service_distributions',
          JSON.stringify(serviceDistributionsObject)
        )
      }
      setServiceDistributions(serviceDistributionsObject)
      formData.append('amount', data.amount)

      sendLinkConfirm(parentParams?.id as string, formData)
        .then((res: any) => {
          setConfirmResponse(res?.data)
          setTempData(data)
          handleClearAndClose()
          setConfirmPopup(true)
        })
        .catch((error: any) => {
          enqueueSnackbar(
            getErrorMessage(error?.response?.data?.error?.message),
            {
              variant: 'error',
            }
          )
        })
    } catch (err: any) {
      console.log(err)
    }
  }

  useEffect(() => {
    handleCallback?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const { handleSubmit } = methods

  const handleClearAndClose = (flag = false) => {
    setRequiredError(false)
    setAmountErrors(null)
    setConfirmPopup(false)
    methods.reset({})
    handleClose(flag)
  }
  const formServiceProps = [
    ...(ServiceItems || []).map((item) => ({
      ...textField(
        item.id,
        item?.name,
        'Amount',
        true,
        8,
        'number',
        item.unique_id,
        item.pending_allocation_amount
      ),
    })),
  ]

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, field: any) => {
    const value = Number(e.target.value) || 0

    setServiceData((prev: any) => {
      const existingItem = prev.find((item: any) => item.id === field?.id)

      if (existingItem) {
        return prev.map((item: any) =>
          item.id === field.id ? { ...item, service_amount: value } : item
        )
      } else {
        return [...prev, { id: field.id, service_amount: value }]
      }
    })
  }

  useEffect(() => {
    const total = serviceData.reduce(
      (sum: any, item: any) => sum + (Number(item.service_amount) || 0),
      0
    )
    methods.setValue('amount', total)
  }, [serviceData])

  useEffect(() => {
    if (ServiceItems && ServiceItems.length > 0) {
      setSchema(sendPaymentSchema)
    } else {
      setSchema(paymentSchema)
    }
  }, [ServiceItems])

  useEffect(() => {
    if (!isDrawerOpen) {
      setRequiredError(false)
      setAmountErrors(null)
      setServiceData([])
      return
    } else {
      if (
        serviceData &&
        serviceData.length > 0 &&
        ServiceItems &&
        ServiceItems.length > 0
      ) {
        if (
          methods.watch('amount') === undefined ||
          methods.watch('amount') === 0
        ) {
          const newErrors: { [key: string]: string } = {}
          ServiceItems.forEach((item: any) => {
            newErrors[item.id] = 'Required'
          })
          // setAmountErrors(newErrors)
          newErrors && setRequiredError(true)
        } else {
          const newErrors: { [key: string]: string } = {}
          ServiceItems.forEach((item: any) => {
            const serviceAmount =
              serviceData.find((service: any) => service.id === item.id)
                ?.service_amount || 0
            if (
              item.pending_allocation_amount !== undefined &&
              serviceAmount > item.pending_allocation_amount
            ) {
              newErrors[item.id] =
                `Amount cannot be greater than ${item.pending_allocation_amount}`
            }
          })
          setRequiredError(false)
          setAmountErrors(newErrors)
        }
      }
    }
  }, [methods.watch('amount')])

  const handleCloseConfirm = () => {
    setServiceData([])
    setAmountErrors(null)
    setConfirmPopup(false)
    setTempData(undefined)
    setConfirmResponse(undefined)
  }

  const format = useCurrencyFormat
  return (
    <>
      <DialogModal
        // isOpen={true}
        small={false}
        className=""
        isOpen={confirmPopup}
        onClose={() => handleCloseConfirm()}
        title={'Are you sure?'}
        onSubmit={() => submitData()}
        secondaryAction={() => handleCloseConfirm()}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <div className="flex flex-col">
            <InfoBox
              content={'Are you sure you want to continue?'}
              className="font-extrabold"
            />
            <div className="px-4 py-2 bg-cardWrapperBg rounded-md ">
              <label
                className="mt-2  mb-0 font-semibold text-base"
                htmlFor={'due_amount'}
              >
                Total Distribution Amount :{' '}
                {format(confirmResponse?.total_distribution_amount) || 0}
              </label>
              <QbsTable
                data={confirmResponse?.service_distributions_splitup}
                columns={getColumns() as any}
                autoHeight={
                  confirmResponse?.service_distributions_splitup.length > 0
                }
              />
            </div>
          </div>
        }
      />
      {
        <CustomDrawer
          className="formDrawer"
          open={isDrawerOpen}
          handleClose={() => handleClearAndClose()}
          actionLoader={isLoading}
          handleSubmit={handleSubmit((data) => onSubmit(data))}
          title="Send Payment Link"
        >
          <div className="flex flex-col gap-4">
            <FormProvider {...methods}>
              {formServiceProps.map((field, index) => (
                <div key={field.id} style={{ marginBottom: field.spacing }}>
                  {requiredError && field.max_amount !== 0 && index === 0 && (
                    // amountErrors &&
                    // amountErrors[field.id] &&
                    <span className="labels label-text text-error">
                      {/* {amountErrors[field.id]} */}
                      {'Fill at least one amount field.'}
                    </span>
                  )}
                  <label htmlFor={field.id} className="labels label-text">
                    Amount{' '}
                    {/* {isAmountRequired && <span className="text-error"> *</span>} */}
                    : {field.label} ({field.unique_id})
                  </label>
                  <TextField
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    placeholder={
                      field.max_amount === 0 ? '0' : field.placeholder
                    }
                    onChange={(e) => handleChange(e, field)}
                    errors={field.max_amount !== 0 && amountErrors}
                    disabled={field.max_amount === 0}
                  />
                  <span className="labels label-text mt-1">
                    Pending Allocation Amount : {field.max_amount}
                  </span>
                  {field.max_amount !== 0 &&
                    amountErrors &&
                    amountErrors[field.id] && (
                      <span className="labels label-text text-error">
                        {amountErrors[field.id]}
                      </span>
                    )}
                </div>
              ))}

              <FormBuilder data={formBuilderProps} edit={true} />
              <div className={`customRadioButton w-auto`}>
                <label className={`labels label-text`}>Advance Payment</label>
                <div className="customRadio-field relative flex items-center gap-4">
                  <div className="flex item-center gap-1 my-2 cursor-pointer">
                    <input
                      type="radio"
                      value="true"
                      id="is_advance"
                      {...methods.register('is_advance')}
                    />
                    <label htmlFor="is_advance" className="cursor-pointer">
                      Yes
                    </label>
                  </div>
                  <div className="flex item-center gap-1 my-2 cursor-pointer">
                    <input
                      type="radio"
                      value="false"
                      id="is_advance2"
                      {...methods.register('is_advance')}
                    />
                    <label htmlFor="is_advance2" className="cursor-pointer">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </FormProvider>
          </div>
        </CustomDrawer>
      }
    </>
  )
}
