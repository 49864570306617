import { useMutation, useQuery } from '@tanstack/react-query'

import {
  deleteData,
  getData,
  patchData,
  postData,
  updateData,
} from '../../apis/api.helpers'
import apiUrl from '../../apis/api.url'
import { QueryParams } from '../../common/types'
import { useSnackbarManager } from '../../components/common/snackbar'
import { getErrorMessage, parseQueryParams } from '../../utilities/parsers'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}

export const createEmployee = (input: any) => {
  return postData(apiUrl.EMPLOYEE_LIST_URL, input)
}

export const useCreateEmployee = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(createEmployee, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      enqueueSnackbar('Employee Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data?.errors?.[1]?.message ?? error.response.data.error
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

const fetchData = async (vendorId: string, input: QueryParams) => {
  const url = buildUrlWithParams(`${apiUrl.VENDOR_URL}${vendorId}/employees`, {
    ...input,
  })
  const response = await getData(url)
  return response
}

export const useEmployee = (vendorId: string, input: QueryParams) => {
  return useQuery(['employee', input], () => fetchData(vendorId, input), {
    // refetchOnWindowFocus: true,
    // staleTime: 0,
  })
}

export const deleteEmployee = (id: any) => {
  return deleteData(`${apiUrl.EMPLOYEE_LIST_URL}${id}`)
}

export const getEmployeeDetails = (vendor_id: string, id: any) => {
  return getData(`${apiUrl.VENDOR_URL}${vendor_id}/employees/${id}/`)
}

export const getEmployeeTeamLists = (vendor_id: string, id: any) => {
  return getData(`${apiUrl.VENDOR_URL}${vendor_id}/employees/${id}/get_team/`)
}

export const editEmployee = (data: {
  input: any
  vendor_id: string
  id: string
}) => {
  return updateData(
    `${apiUrl.VENDOR_URL}${data?.vendor_id}/employees/${data?.id}/`,
    data?.input
  )
}

export const useEditEmployee = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()

  return useMutation(editEmployee, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Updated successfully', {
        variant: 'success',
      })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error), {
        variant: 'error',
      })
    },
  })
}

export const getRoles = (id: any, input: any) => {
  return getData(`${apiUrl.VENDOR_URL}${id}/group/${parseQueryParams(input)}`)
}

export const getEmployeeTeamList = (input: any, vendor_id: string) => {
  return getData(
    `${apiUrl.VENDOR_URL}${vendor_id}/teams${parseQueryParams(input)}`
  )
}
export const editEmployeeRole = (data: {
  input: any
  id: string
  id2: string
}) => {
  return patchData(
    `${apiUrl.VENDOR_URL}${data?.id}/employees/${data?.id2}/change_role/`,
    data?.input
  )
}
export const useEditEmployeeRole = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()

  return useMutation(editEmployeeRole, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Updated successfully', {
        variant: 'success',
      })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error), {
        variant: 'error',
      })
    },
  })
}
export const setEmployeeTeamStatus = (
  vendorId: any,
  memberId: string,
  status: boolean,
  data: any
) => {
  const url = status ? 'deactivate_team' : 'activate_team'
  return patchData(
    `${apiUrl.VENDOR_URL}${vendorId}/employees/${memberId}/${url}/`,
    data
  )
}

export const addTeamEmployee = (input: {
  vendor_id: any
  team_id: any
  data: any
}) => {
  return postData(
    `${apiUrl.VENDOR_URL}${input.vendor_id}/teams/${input.team_id}/members/`,
    input.data
  )
}

//develop.api.bizpole.in/api/e/franchisee/franchisees/?listing=true&page=1&page_size=30&
export const getAllFranchiseeList = (input: any) => {
  return getData(`${apiUrl.FRANCHISEES}${parseQueryParams(input)}`)
}
export const getEmployeeFranchiseeList = (id: any) => {
  return getData(`${apiUrl.EMPLOYEE_LIST_URL}${id}/get-franchisee`)
}
export const getNotificationEntities = () => {
  return getData(`${apiUrl.NOTIFICATION_ENTITIES}`)
}

export const setDefaultEmployeeFranchisee = (id: string, input: any) => {
  return postData(
    `${apiUrl.EMPLOYEE_LIST_URL}${id}/assign-default-franchisee`,
    input
  )
}

export const getDepartment = (input: any) => {
  return getData(`${apiUrl.DEPARTMENTS}${parseQueryParams(input)}`)
}

export const getDesignation = (input: any) => {
  return getData(`${apiUrl.DESIGNATION}${parseQueryParams(input)}`)
}

export const getReportsTo = (vendor_id: string, input: any) => {
  return getData(
    `${apiUrl.VENDOR_URL}${vendor_id}/employees${parseQueryParams(input)}`
  )
}

export const editWorkProfile = (data: {
  input: any
  vendor_id: string
  id: string
}) => {
  return patchData(
    `${apiUrl.VENDOR_URL}${data?.vendor_id}/employees/${data?.id}/update_profile/`,
    data?.input
  )
}

export const useEditWorkProfile = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()

  return useMutation(editWorkProfile, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Updated successfully', {
        variant: 'success',
      })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error), {
        variant: 'error',
      })
    },
  })
}

export const setAdminStatus = (input: { id: any; data: any }) => {
  return postData(
    `${apiUrl.EMPLOYEE_LIST_URL}${input.id}/set-admin-status`,
    input.data
  )
}

export const setOperationsHeadStatus = (input: { id: any; data: any }) => {
  return postData(
    `${apiUrl.EMPLOYEE_LIST_URL}${input.id}/set-operations-head`,
    input.data
  )
}

export const setEmployeeStatus = (input: {
  vendor_id: any
  id: any
  data: any
}) => {
  const status = input.data.is_active ? 'activate' : 'deactivate'

  return patchData(
    `${apiUrl.VENDOR_URL}${input.vendor_id}/employees/${input.id}/${status}/`,
    input.data
  )
}

export const setPassword = (vendor_id: string, id: string, data: any) => {
  return patchData(
    `${apiUrl.VENDOR_URL}${vendor_id}/employees/${id}/change_password/`,
    data
  )
}

export const getFranchesiRoles = (input: any) => {
  return getData(`${apiUrl.ROLE_LIST_URL}${parseQueryParams(input)}`)
}

export const setFranchesiRoles = (input: { id: any; data: any }) => {
  return postData(
    `${apiUrl.VENDOR_DETAILS}${input.id}/change-employee-role`,
    input.data
  )
}
