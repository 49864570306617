import moment from 'moment'
import { QbsTable } from 'qbs-react-grid'
import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import apiUrl from '../../apis/api.url'
import InfoBox from '../../components/app/alertBox/infoBox'
import ExportComponent from '../../components/app/export/index'
import { PrimaryFilterComponent } from '../../components/app/filterComponents/primaryFilter'
import ShareReceipt from '../../components/app/payment/receipt/shareReceipt'
import { DialogModal, TextArea } from '../../components/common'
import Button from '../../components/common/buttons/Button'
import Icons from '../../components/common/icons/index'
import ListingHeader from '../../components/common/ListingTiles/index'
import PageTitle from '../../components/common/PageTitle/index'
import { useSnackbarManager } from '../../components/common/snackbar'
import { blockActionsbyStatus } from '../../configs/permissionGate'
import { router_config } from '../../configs/route.config'
import { MODULES } from '../../configs/scopes.config'
import { usePaymentsFilterStore } from '../../store/filterSore/paymentStore'
import { calcWindowHeight } from '../../utilities/calcHeight'
import { downloadFromServer } from '../../utilities/commonUtilities'
import { statusClassGen } from '../../utilities/generators'
import { getSortedColumnName } from '../../utilities/parsers'
import {
  TABlE_COLUMNS,
  useTablePreference,
} from '../../utilities/tableprefrences/index'
import EditPayment from '../order/details/payments/EditPayment'
import { deletePayment, usePayments, verifiyPayments } from './api'
import { GetFilterDetails } from './filterProperties'
import { getColumns } from './paymentsColumns'
import { checkDetailPermission, checkPermission } from './permission'

const Payments = () => {
  const [qryParams, setQryParams] = useSearchParams()
  const { enqueueSnackbar } = useSnackbarManager()
  const [openExport, setExportOpen] = useState(false)
  const [type, setType] = useState<string | null | undefined>('all')
  const { getSavedColumns, handleSaveColumns } = useTablePreference()

  useEffect(() => {
    setType(qryParams.get('type'))
  }, [qryParams])
  const {
    pageParams,
    setPageParams,
    selectedRows,
    setSelectedRows,
    advanceFilter,
    setAdvanceFilter,
  } = usePaymentsFilterStore()

  const { page, page_size, search, ordering, filters, filterProps } = pageParams
  const [showDetail, setShowDetail] = useState(true)
  const [openManage, setOpenManage] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [item, setItem] = useState<string | null>(null)
  const [remark, setRemark] = useState('')
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [shareReceipt, setshareReceipt] = useState(false)
  const [selectedPayment, setSelectedPayment] = useState<string | null>(null)
  const searchParams = {
    page: page,
    page_size: page_size,
    search: search,
    ordering: ordering,
    type: qryParams.get('type') || 'all',
    ...filters,
  }
  const { ...pageFilters } = searchParams
  const { data, refetch, isFetching } = usePayments({
    input: { ...pageFilters },
    type: type,
  })

  // useEffect(() => {
  //   refetch()
  // }, [page, page_size, search, ordering, filters, refetch])

  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const onViewAction = (r: any, from: string) => {
    if (from == 'accountName') {
      return `${router_config.ACCOUNTS.path}/${r.account?.id}/summary`
    } else if (from == 'orderId') {
      return `${router_config.SERVICE_AND_ORDERS_BY_ORDERS.path}/${r?.order?.id}/summary`
    } else if (from == 'receiptNumber') {
      return `/payments/receipt/${r.id}`
    } else if (from == 'transactionId') {
      setSelectedPayment(r?.id)
      setOpenEdit(false)
      setOpenManage(true)
    } else {
      handlePaymentRecieptOpen(r)
    }
  }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }
  const handleColumnToggle = async (columns: any) => {
    handleSaveColumns(columns, TABlE_COLUMNS.PAYMENT)
  }
  const basicData = {
    title: 'Payment',
    icon: 'user',
  }
  const handleClose = () => {
    setExportOpen(false)
  }

  const handlePaymentRecieptOpen = (data: any) => {
    if (data?.id) {
      window.open(`/payments/receipt/${data.id}`, '_blank')
    }
  }

  const filterDetails = GetFilterDetails()

  const handleFilterChange = (dta: any, name: string, id: string) => {
    const currentParams = usePaymentsFilterStore.getState()?.pageParams || {}

    const getDefaultFilters = () => ({
      ...currentParams.filters,
      [id]: dta?.id ?? undefined,
    })

    const getDefaultFilterProps = () => ({
      ...currentParams.filterProps,
      [name]: dta[name] ?? undefined,
    })
    const filters = getDefaultFilters()
    const filterProps = getDefaultFilterProps()

    setPageParams({
      ...currentParams,
      page: 1,
      filters,
      filterProps,
    })
  }

  useEffect(() => {
    if (
      filterProps.endDate ||
      (filterProps.endDate === null && filterProps.startDate === null)
    ) {
      handleDatepicker()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        fromDate: filterProps?.startDate
          ? moment(filterProps?.startDate).format('DD-MM-YYYY')
          : '',
        toDate: filterProps?.endDate
          ? moment(filterProps.endDate).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  useEffect(() => {
    if (
      filterProps.created_to_date ||
      (filterProps.created_to_date === null &&
        filterProps.created_from_date === null)
    ) {
      handleInitiatedDatepicker()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.created_to_date])

  const handleInitiatedDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        created_from_date: filterProps?.created_from_date
          ? moment(filterProps?.created_from_date).format('DD-MM-YYYY')
          : '',
        created_to_date: filterProps?.created_to_date
          ? moment(filterProps.created_to_date).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.created_to_date])

  const handleFilterDateChange = (event: any, desc: any, desc2: any) => {
    setPageParams({
      ...pageParams,
      filterProps: {
        ...filterProps,
        [desc]: event?.value[0],
        [desc2]: event?.value[1],
      },
    })
  }

  const handleSHowHide = () => {
    return advanceFilter?.filterParams?.find((item) => item.isChecked)
      ? true
      : false
  }
  const [columns, setColumns] = useState<any>([])
  useEffect(() => {
    setColumns(
      getSavedColumns(
        getColumns({
          onViewAction: onViewAction,
          statusClassGen: statusClassGen,
        }),
        TABlE_COLUMNS.PAYMENT
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
    handleSaveColumns(
      getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
      TABlE_COLUMNS.PAYMENT
    )
  }
  const handleEdit = (row: any) => {
    setOpenEdit(true)
    setSelectedPayment(row?.id)
    setOpenManage(true)
  }
  const handleCloseMange = (flag?: boolean) => {
    setOpenManage(false)
    setOpenEdit(false)
    setSelectedPayment(null)
    if (flag) {
      refetch()
    }
  }

  const handleVerificationModalClose = () => {
    setItem(null)
    setConfirmationModal(false)
  }

  const handleVerify = (from: string) => {
    verifiyPayments({
      id: item ?? '',
      input: {
        verification_status_type: from == 'verify' ? 'verify' : 'reject',
        remark: remark,
      },
    })
      .then((res) => {
        enqueueSnackbar(
          res.message
            ? res.message
            : from == 'verify'
              ? 'Verified successfully'
              : 'Rejected successfully',
          {
            variant: 'success',
          }
        )
        refetch()
        setConfirmationModal(false)
      })
      .catch((err) => {
        enqueueSnackbar(err?.response?.data?.error?.message, {
          variant: 'error',
        })
      })
  }
  const handleDeleteModal = (rowData: any) => {
    setItem(rowData.id)
    setDeleteModal(true)
  }
  const handleDeleteModalClose = () => {
    setItem(null)
    setDeleteModal(false)
  }
  const handleDelete = () => {
    deletePayment(item as string)
      .then((res) => {
        enqueueSnackbar(res.message ? res.message : 'Deleted successfully', {
          variant: 'success',
        })
        refetch()
        setSelectedRows(selectedRows?.filter((sel: any) => sel !== item) || [])
        setDeleteModal(false)
      })
      .catch((err) => {
        enqueueSnackbar(err?.response?.data?.error?.message, {
          variant: 'error',
        })
      })
  }
  const handleDownload = (row: any) => {
    if (row?.receipt) {
      downloadFromServer(row.receipt, row.display_file_name)
    }
  }
  const handleShare = (row: any) => {
    setshareReceipt(true)
    setSelectedPayment(row?.id)
  }
  const manageManagedList = (code: any) => {
    if (code === 'govt') {
      setQryParams({ additional_payment_type__category: 'govt' })
    }
    setQryParams({ type: code })
    setPageParams({
      ...pageParams,
      page: 1,
    })
  }
  const groupdata = [
    { name: 'All Payments', code: 'all' },
    { name: 'Verified Payments', code: 'verified' },
    { name: 'Not Verified Payments', code: 'not_verified' },
    { name: 'Rejected Payments', code: 'rejected' },
    { name: 'Additional Govt. Payments', code: 'govt' },
  ]
  return (
    <div className="con">
      <ListingHeader
        data={basicData}
        groupData={groupdata}
        showManagedList
        setGroupNameCode={manageManagedList}
        groupValue={searchParams.type}
        permissions={{
          export: 'export_payments',
        }}
        onHandleExport={() => setExportOpen(true)}
        checkPermission={checkPermission}
        actionProps={{ hideExport: data?.results?.length > 0 ? false : true }}
      />
      <PageTitle data={data?.total} isLoading={isFetching} />

      <div className=" p-4">
        <QbsTable
          data={data?.results ?? []}
          toolbar={true}
          selectedRows={selectedRows}
          selection={true}
          // searchValue={searchKey}
          handleColumnToggle={handleColumnToggle}
          handleResetColumns={() => handleResetColums()}
          search={true}
          height={calcWindowHeight(422)}
          isLoading={isFetching}
          tableBodyHeight="calc(100vh - 332px)"
          sortType={pageParams.sortType}
          sortColumn={pageParams.sortColumn}
          handleColumnSort={handleSort}
          columns={columns}
          pagination={true}
          paginationProps={{
            onPagination: onChangePage,
            total: data?.count,
            currentPage: pageParams?.page,
            rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
            onRowsPerPage: onChangeRowsPerPage,
            dropOptions: process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN
              ? JSON.parse(process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN)
              : [10, 20, 30, 50],
          }}
          actionProps={[
            {
              icon: <Icons name="payment-receipt" />,
              action: (row) => handlePaymentRecieptOpen(row),
              title: 'View Receipt',
              toolTip: 'View Receipt',
              hidden: !checkDetailPermission('view', MODULES.receipt),
              hide: (row: any) =>
                row.payment_status_label === 'Pending' ||
                row?.payment_verification_status?.code === 'not_verified' ||
                row?.payment_verification_status?.code === 'rejected' ||
                blockActionsbyStatus(row.order?.order_status?.code, [
                  // 'cancelled',
                  // 'order_cancelled',
                  'rejected',
                ]),
            },
            {
              icon: <Icons name="edit" />,
              action: (row) => handleEdit(row),
              title: 'Edit',
              toolTip: 'Edit',
              hide: (row) =>
                (row.payment_verification_status &&
                  row.payment_verification_status?.code !== 'not_verified') ||
                blockActionsbyStatus(row.order?.order_status?.code, [
                  'cancelled',
                  'order_cancelled',
                  'rejected',
                  'order_completed_payment_done',
                ]),
              hidden: !checkPermission('change'),
            },

            {
              icon: <Icons name="delete" />,
              action: (row) => handleDeleteModal(row),
              title: 'Delete',
              hide: (row) =>
                row?.payment_verification_status?.code !== 'not_verified' ||
                blockActionsbyStatus(row.order?.order_status?.code, [
                  'cancelled',
                  'order_cancelled',
                  'rejected',
                  'order_completed_payment_pending',
                  'order_completed_payment_done',
                ]),
              toolTip: 'Delete',
              hidden: !checkPermission('delete'),
            },

            {
              icon: <Icons name="download" />,
              action: (row: any) => handleDownload(row),
              title: 'Download',
              toolTip: 'Download',
              hidden: !checkDetailPermission('download', MODULES.receipt),
              hide: (row: any) =>
                row.payment_status_label === 'Pending' ||
                row?.payment_verification_status?.code === 'not_verified' ||
                blockActionsbyStatus(row.order?.order_status?.code, [
                  'cancelled',
                  'order_cancelled',
                  'rejected',
                ]),
            },
            {
              icon: <Icons name="share" />,
              action: (row: any) => handleShare(row),
              title: 'Share',
              toolTip: 'Share',
              hidden: !checkDetailPermission('share', MODULES.receipt),
              hide: (row: any) =>
                row.payment_status_label === 'Pending' ||
                row?.payment_verification_status?.code === 'not_verified' ||
                blockActionsbyStatus(row.order?.order_status?.code, [
                  'cancelled',
                  'converted',
                  'rejected',
                  'order_cancelled',
                ]),
            },
          ]}
          primaryFilter={
            <PrimaryFilterComponent
              filterParams={filterDetails}
              handleFilterChange={handleFilterChange}
              filterProps={filterProps}
              advanceFilter={advanceFilter}
              handleFilterDateChange={handleFilterDateChange}
              isPrimary={true}
              setAdvanceFilter={setAdvanceFilter}
            />
          }
          advancefilter={
            showDetail && (
              <PrimaryFilterComponent
                filterParams={filterDetails}
                handleFilterDateChange={handleFilterDateChange}
                handleFilterChange={handleFilterChange}
                filterProps={filterProps}
                isPrimary={false}
                advanceFilter={advanceFilter}
              />
            )
          }
          searchValue={pageParams?.search}
          onSearch={handleSeach}
          asyncSearch
          handleSearchValue={(key?: string) => handleSeach(key)}
          onSelect={handleOnSlect}
          columnToggle
          tableHeaderActions={
            <div className="flex gap-2">
              {handleSHowHide() && (
                <Button
                  onClick={() => {
                    setShowDetail(!showDetail)
                  }}
                  label={showDetail ? 'Hide' : 'Show'}
                  className="se"
                  primary
                />
              )}
            </div>
          }
        />
        <ShareReceipt
          isDrawerOpen={shareReceipt}
          handleClose={() => setshareReceipt(false)}
          id={selectedPayment as string}
        />
        <ExportComponent
          isOpen={openExport}
          handleClose={handleClose}
          slug={'PAYMENT_EXPORT'}
          pageParams={{
            ...searchParams,
            type: undefined,
            status_code: searchParams.type,
          }}
        />
        <EditPayment
          isDrawerOpen={openManage}
          handleClose={handleCloseMange}
          edit={openEdit}
          handleCallback={openManage ? refetch : ''}
          id={selectedPayment as string}
          url={apiUrl.PAYMENT}
        />
        <DialogModal
          isOpen={deleteModal}
          onClose={() => handleDeleteModalClose()}
          title={'Are you sure?'}
          onSubmit={() => handleDelete()}
          secondaryAction={() => handleDeleteModalClose()}
          secondaryActionLabel="No, Cancel"
          actionLabel="Yes, I am"
          body={
            <InfoBox
              content={
                'Deleting this item is an irreversible action. Are you sure you want to proceed with the deletion?'
              }
            />
          }
        />

        <DialogModal
          isOpen={confirmationModal}
          onClose={() => handleVerificationModalClose()}
          title={'Verify Payment Entry'}
          onSubmit={() => handleVerify('verify')}
          secondaryAction={() => handleVerify('reject')}
          secondaryActionLabel="No, Reject"
          actionLabel="Yes, Verify"
          body={
            <InfoBox
              content={
                <div>
                  <div className="mb-5">
                    Confirm a manual payment entry post bank/account
                    reconciliation. Please be aware that this action is
                    irreversible, and once verified, the payment entry cannot be
                    edited or deleted.
                  </div>
                  <TextArea
                    id="1"
                    name="reason"
                    label="Verification Remarks"
                    onChange={(e) => setRemark(e.target.value)}
                    placeholder="Enter Verification Remarks"
                    required={false}
                  />
                </div>
              }
            />
          }
        />
      </div>
    </div>
  )
}

export default Payments
