import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import InfoBox from '../../../../components/app/alertBox/infoBox'
import { Button, DialogModal } from '../../../../components/common'
import Icons from '../../../../components/common/icons'
import { useSnackbarManager } from '../../../../components/common/snackbar'
import { useAuthStore } from '../../../../store/authStore'
import { statusClassGen } from '../../../../utilities/generators'
import { getErrorMessage } from '../../../../utilities/parsers'
import { cancelActivityRequest } from '../../../activities/api'
import ReviewActivityRequests from '../../../activities/details/requests/review'
import AddPayout from './addPayout/addPayout'
import { useServicePaymentRequestDetails } from './api'
import ManagePaymentRequests from './manage'
import { getColumns } from './paymentRequestColumns'
import { checkPermission } from './permission'

const ServicePayments = () => {
  const { setActiveTab, detailData, handleParentCallBack } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleParentCallBack: () => void
    detailData: any
  }>()
  const [activeRow, setActiveRow] = useState<any>(null)
  const [reviewOpen, setReviewOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [openPayoutPopup, setOpenPayoutPopup] = useState(false)
  const { userData } = useAuthStore()
  const [deleteOpen, setDeleteOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbarManager()

  const params = useParams()
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      setActiveTab('SERVICE_AND_ORDERS_BY_SERVICE_PAYMENT_APPROVAL')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { data, isFetching, refetch } = useServicePaymentRequestDetails(
    detailData?.order?.id,
    params?.id as string,
    {}
  )
  const handleCloseAll = () => {
    setDeleteOpen(false)
    setReviewOpen(false)
    setActiveRow(null)
    setIsOpen(false)
  }
  const onViewAction = (r: any, from: string) => {
    if (from == 'linkTo') {
      setActiveRow(r)
      setReviewOpen(true)
    }
  }
  const handleAction = () => {
    setIsOpen(true)
  }
  const handleCallback = () => {
    refetch()
    handleParentCallBack()
  }
  const handlePayoutPopup = (row: any) => {
    setActiveRow(row)
    setOpenPayoutPopup(true)
  }
  const handleCloseMange = () => {
    setOpenPayoutPopup(false)
    refetch()
  }

  const handleDeleteOpen = (row: any) => {
    setActiveRow(row)
    setDeleteOpen(true)
  }
  const handleDelete = () => {
    cancelActivityRequest(activeRow?.id)
      .then(() => {
        enqueueSnackbar('Request cancelled successfully', {
          variant: 'success',
        })
        handleCloseAll()

        refetch()
        handleParentCallBack()
      })
      .catch((error: any) => {
        enqueueSnackbar(
          getErrorMessage(
            error?.response?.data?.error?.message[0] ??
              error?.response?.data?.error.message
          ),
          {
            variant: 'error',
          }
        )
      })
  }
  const feeList = {
    maxPenalty: detailData?.requestable_penalty,
    maxLateFee: detailData?.requestable_late_fees,
    maxGovtTax: detailData?.requestable_govt_taxes,
    maxGovFee: detailData?.requestable_govt_fee,
    maxvendorFee: detailData?.requestable_vendor_fee,
  }
  const AddPayoutHandleCallback = () => {
    refetch()
    handleParentCallBack()
  }

  return (
    <div className=" p-4">
      <QbsTable
        data={data?.data ?? []}
        dataRowKey="id"
        toolbar={true}
        search={true}
        isLoading={isFetching}
        tableHeaderActions={
          <div className="flex gap-2">
            <Button
              label={'Add New'}
              hidden={!checkPermission('add')}
              icon={'plus'}
              onClick={handleAction}
            />
          </div>
        }
        columns={
          getColumns({
            onViewAction: onViewAction,
            statusClassGen: statusClassGen,
          }) as any
        }
        columnToggle
        actionProps={[
          {
            title: 'Mark as Paid',
            icon: <Icons name="payment-verify" />,
            action: (row: any) => handlePayoutPopup(row),
            toolTip: 'Mark as Paid',
            hide: (row: any) => !row?.can_mark_as_paid,
          },
          {
            icon: <Icons name="close" />,
            action: (row: any) => handleDeleteOpen(row),
            title: 'Cancel Request',
            toolTip: 'Cancel Request',
            // hidden: !checkDetailPermission('delete', MODULES.request),
            hide: (row: any) =>
              row?.status?.code !== 'requested' ||
              userData?.id !== row?.created_by?.id,
          },
        ]}
      />
      <ManagePaymentRequests
        isDrawerOpen={isOpen}
        service_id={params?.id}
        order_id={detailData?.order?.id}
        handleClose={() => handleCloseAll()}
        handleCallback={handleCallback}
        feeList={feeList}
      />
      <ReviewActivityRequests
        isDrawerOpen={reviewOpen}
        data={activeRow}
        disabled={true}
        handleClose={() => handleCloseAll()}
      />
      <AddPayout
        isDrawerOpen={openPayoutPopup}
        subSection={true}
        data={activeRow}
        handleClose={handleCloseMange}
        handleCallback={AddPayoutHandleCallback}
      />
      <DialogModal
        isOpen={deleteOpen}
        onClose={() => handleCloseAll()}
        title={'Are you sure?'}
        onSubmit={() => handleDelete()}
        secondaryAction={() => handleCloseAll()}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <InfoBox content={'Are you sure you want to cancel this request?'} />
        }
      />
    </div>
  )
}

export default ServicePayments
