import moment from 'moment'

import {
  getNestedProperty,
  useCurrencyFormat,
} from '../../../../utilities/parsers'

const defaultColumnProps3 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({ onViewAction, statusClassGen }: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    const format = useCurrencyFormat
    switch (isCustom) {
      case 'amount':
        const propertyValue = getNestedProperty(row, key)
        const numbers =
          typeof propertyValue === 'string' ? parseFloat(propertyValue) : 0

        return {
          cell: numbers === 0 ? '--' : format(numbers),
          toolTip: getNestedProperty(row, key) ?? '',
        }
      case 'status':
        return {
          cell: (
            <div className="text-capitalize">
              <span
                className={`statusLabel  ${
                  row.status?.name
                    ? statusClassGen(row?.status?.name)
                    : 'Active'
                }`}
              >
                {row?.status?.name}
              </span>
            </div>
          ),
          toolTip: getNestedProperty(row, key),
        }
      case 'request_date':
        return {
          cell: (
            <>
              {row?.request_date
                ? moment(row?.request_date).format('DD-MM-YYYY')
                : '--'}
            </>
          ),
        }
      case 'approved_date':
        return {
          cell: (
            <>
              {row?.approved_date
                ? moment(row?.approved_date).format('DD-MM-YYYY')
                : '--'}
            </>
          ),
        }
      case 'followup': {
        const followup_date = moment(row?.target_date)
        const today = moment(new Date())
        const formattedDate = moment(row?.target_date).format('DD-MM-yyyy')
        return {
          cell: (
            <>
              {today.isAfter(followup_date, 'day') ? (
                <span className="text-red selected-item text-sm inline-block">
                  {row?.target_date ? formattedDate : row?.target_date}
                </span>
              ) : row?.target_date ? (
                formattedDate
              ) : (
                row?.target_date
              )}
            </>
          ),
          toolTip: getNestedProperty(row, key) ?? '',
        }
      }
      case 'task_details': {
        return {
          cell: (
            <div className="text-capitalize">
              {/* <span
                  className={`statusLabel  ${
                    row.status?.name
                      ? statusClassGen(row?.status?.name)
                      : 'Active'
                  }`}
                > */}
              {row?.task}
              {/* </span> */}
            </div>
          ),
          toolTip: getNestedProperty(row, key) ?? '',
        }
      }
      default:
        return {
          cell: getNestedProperty(row, key),
          toolTip: getNestedProperty(row, key) ?? '',
        }
    }
  }
  const column = [
    {
      title: 'Payout ID',
      field: 'unique_id',
      rowClick: (row: any) => onViewAction(row),
      link: true,
      fixed: true,
      ...defaultColumnProps3,
      // customCell: true,
    },
    {
      title: 'Payment Date ',
      field: 'payment_date',
      // rowClick: (row: any) => onViewAction(row, 'linkTo'),
      // link: true,
      ...defaultColumnProps3,
      // customCell: true,
    },
    {
      title: 'Amount',
      field: 'amount',
      // rowClick: (row: any) => onViewAction(row, 'linkTo'),
      // link: true,
      // fixed: true,
      ...defaultColumnProps3,
      // customCell: true,
      align: 'right',
    },
    {
      title: 'Bizpole Payable Amount',
      field: 'bizpole_payable_amount',
      ...defaultColumnProps3,
      renderCell: createRenderCell('bizpole_payable_amount'),
      colWidth: 220,
      customCell: true,
      align: 'right',
    },
    {
      title: 'Payment Method ',
      field: 'payment_method',
      renderCell: createRenderCell('paymentout_method.name'),
      // rowClick: (row: any) => onViewAction(row, 'linkTo'),
      // link: true,
      // fixed: true,
      ...defaultColumnProps3,
      customCell: true,
    },
    {
      title: 'Payment Type',
      field: 'payment_type',
      ...defaultColumnProps3,
      renderCell: createRenderCell('paymentout_type.name'),
      customCell: true,
    },
    {
      title: 'Transaction Number',
      field: 'transaction_number',
      // rowClick: (row: any) => onViewAction(row, 'linkTo'),
      // link: true,
      // fixed: true,
      ...defaultColumnProps3,
      // customCell: true,
    },
    {
      title: 'Receipt Number',
      field: 'receipt_number',
      // rowClick: (row: any) => onViewAction(row, 'linkTo'),
      // link: true,
      // fixed: true,
      ...defaultColumnProps3,
      // customCell: true,
    },
    {
      title: 'Remarks',
      field: 'remark',
      // rowClick: (row: any) => onViewAction(row, 'linkTo'),
      // link: true,
      // fixed: true,
      ...defaultColumnProps3,
      // customCell: true,
    },

    // {
    //   title: 'Files',
    //   field: 'name',
    //   renderCell: createRenderCell('files_count'),
    //   ...defaultColumnProps3,
    //   customCell: true,
    // },
  ]

  return column
}
