import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'

import FormBuilder from '../../../../../components/app/formBuilder'
import CustomDrawer from '../../../../../components/common/drawer'
import { useSnackbarManager } from '../../../../../components/common/snackbar'
import { getApprovalReqType, useCreateActivityRequests } from '../api'
import {
  ACCEPTED_IMAGE_TYPES,
  attachmentsSchema,
  specialFeeSchem,
} from './schema'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  service_id?: string
  order_id?: string
  handleCallback?: () => void
  feeList?: any
}
interface reqForProps {
  id: string
  name: string
}

export default function ManagePaymentRequests({
  isDrawerOpen,
  handleClose,
  service_id,
  order_id,
  handleCallback,
  feeList,
}: Props) {
  const handleClearAndClose = () => {
    handleReset()
    handleClose()
  }
  const [schema, setSchema] = useState<any>()

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    ...(required ? { required: true } : {}),
  })
  const getApprovalFor = async () => {
    const { data } = await getApprovalReqType({
      type: 'dropdown',
      requires_payment: true,
    })
    const extractedStatuses: reqForProps[] = []

    data?.forEach((result: reqForProps) => {
      const { id, name } = result
      extractedStatuses.push({ id, name })
    })

    return extractedStatuses
  }

  const methods = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const prevApprovalTypeRef = useRef<string | undefined>(
    methods.watch('approval_type_name')
  )
  useEffect(() => {
    if (
      ['Penalty', 'Late Fees', 'Govt. Taxes'].includes(
        methods.watch('approval_type_name')
      )
    ) {
      methods.clearErrors()
      setSchema(specialFeeSchem)
    } else {
      methods.clearErrors()
      setSchema(attachmentsSchema)
    }
    const approvalTypeName = methods.watch('approval_type_name') as string

    if (!methods.watch('approval_type_name')) {
      methods.setValue('amount', '')
      methods.setValue('bizpole_payable_amount', '')
    }

    if (
      prevApprovalTypeRef.current !== undefined &&
      prevApprovalTypeRef.current !== approvalTypeName
    ) {
      methods.setValue('amount', '')
      methods.setValue('bizpole_payable_amount', '')
    }

    prevApprovalTypeRef.current = approvalTypeName

    if (approvalTypeName === 'Government Payment Approval') {
      methods.setValue('maxAmount', feeList?.maxGovFee?.toString())
    } else if (approvalTypeName === 'Vendor Payment Approval') {
      methods.setValue('maxAmount', feeList?.maxvendorFee?.toString())
    } else if (approvalTypeName === 'Govt. Taxes') {
      methods.setValue('maxAmount', feeList?.maxGovtTax.toString())
    } else if (approvalTypeName === 'Late Fees') {
      methods.setValue('maxAmount', feeList?.maxLateFee.toString())
    } else if (approvalTypeName === 'Penalty') {
      methods.setValue('maxAmount', feeList?.maxPenalty.toString())
    } else {
      methods.setValue('maxAmount', '')
      methods.clearErrors('amount')
    }
  }, [methods.watch('approval_type_name'), methods.watch('amount')])

  const formBuilderProps = [
    {
      name: 'approval_type_name',
      label: 'Request for',
      getData: getApprovalFor,
      async: false,
      id: 'approval_type',
      descId: 'id',
      initialLoad: true,
      required: true,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Request for',
    },
    {
      ...textField(
        'maxAmount',
        'Maximum Requestable Amount',
        'Maximum Requestable Amount'
      ),
      type: 'text',
      required: false,
      disabled: true,
      hidden:
        !methods.watch('approval_type_name') ||
        methods.watch('approval_type_name') === 'Refund Approval',
    },
    {
      ...textField('amount', 'Amount', 'Enter Amount'),
      type: 'number',
      required:
        methods.watch('approval_type_name') === '' ||
        methods.watch('approval_type_name') === undefined ||
        methods.watch('approval_type_name') === 'Refund Approval' ||
        methods.watch('approval_type_name') === 'Government Payment Approval' ||
        methods.watch('approval_type_name') === 'Vendor Payment Approval'
          ? true
          : false,
    },
    {
      ...textField(
        'bizpole_payable_amount',
        'Bizpole Payable Amount',
        'Bizpole Payable Amount'
      ),
      type: 'text',
      required: false,
      disabled: false,
      hidden:
        !methods.watch('approval_type_name') ||
        methods.watch('approval_type_name') === 'Refund Approval' ||
        methods.watch('approval_type_name') === 'Government Payment Approval' ||
        methods.watch('approval_type_name') === 'Vendor Payment Approval',
    },

    {
      ...textField('request_remark', 'Add Notes', 'Add Notes', false),
      type: 'textarea',
    },
    {
      name: 'attachments',
      required: false,
      id: 'attachments',
      label: 'Attach file',
      descId: 'id',
      isMultiple: true,
      supportedFiles: ACCEPTED_IMAGE_TYPES,
      supportedExtensions: ACCEPTED_IMAGE_TYPES,
      fileSize: 50,
      type: 'file_upload',
    },
  ]

  const handleSubmission = () => {
    methods.reset()
    handleClearAndClose()
    handleCallback?.()
  }
  const handleReset = () => {
    methods.reset({
      approval_type_name: undefined,
      approval_type: undefined,
      amount: undefined,
      request_remark: undefined,
      attachments: undefined,
    })
  }
  useEffect(() => {
    handleReset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])
  const { mutate, isLoading } = useCreateActivityRequests(
    handleSubmission,
    service_id,
    order_id
  )
  const { enqueueSnackbar } = useSnackbarManager()

  const onSubmit = (data: z.infer<typeof schema>) => {
    if (
      ['Penalty', 'Late Fees', 'Govt. Taxes'].includes(
        methods.watch('approval_type_name')
      ) &&
      (data?.amount == '' || data?.amount == undefined) &&
      (data?.bizpole_payable_amount == '' ||
        data?.bizpole_payable_amount == undefined)
    ) {
      enqueueSnackbar(
        'Provide either Amount or Bizpole payable amount to proceed. ',
        {
          variant: 'error',
        }
      )
      return
    }

    const formData = new FormData()
    formData.append('approval_type', data?.approval_type)
    formData.append('amount', String(data?.amount || 0))
    formData.append('request_remark', data?.request_remark ?? '')
    formData.append(
      'bizpole_payable_amount',
      String(data?.bizpole_payable_amount || 0)
    )

    data?.attachments?.forEach((atta: any) => {
      formData.append(`attachments`, atta)
    })

    mutate(formData)
  }
  const { handleSubmit } = methods

  return (
    <CustomDrawer
      className="formDrawer"
      open={isDrawerOpen}
      handleClose={() => handleClearAndClose()}
      handleSubmit={handleSubmit((data) => onSubmit(data))}
      disableSubmit={isLoading}
      title="Raise Request"
      actionLoader={isLoading}
    >
      <div className="flex flex-col gap-4">
        <FormProvider {...methods}>
          <FormBuilder data={formBuilderProps} edit={true} />
        </FormProvider>
      </div>
    </CustomDrawer>
  )
}
