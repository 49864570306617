import { z } from 'zod'

import noLeadingSpaces from '../../../../../utilities/noLeadingSpaces'

const MAX_FILE_SIZE = 5000000

export const ACCEPTED_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf',
]

export const paymentSchema = z.object({
  transaction_date: z.date().refine((date) => Boolean(date), {
    message: 'Transaction date is required.',
  }),
  transaction_id: z
    .string()
    .min(1, { message: 'Transaction id is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  amount: z.number().optional(),
  payment_method_id: z
    .string({ required_error: 'Required', invalid_type_error: 'Required' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  payment_method_name: z
    .string({
      required_error: 'Required',
      invalid_type_error: 'Required',
    })
    .min(1, { message: 'Required' }),
  internal_notes: z.string().optional().nullable(),
  receipt_number: z
    .string()
    .min(1, { message: 'Receipt number is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  is_advance: z.string().optional(),
  payment_document: z
    .union([z.instanceof(File), z.any()])
    .refine((file) => !(file instanceof File) || file.size <= MAX_FILE_SIZE, {
      message: 'Maximum size 5mb',
    })
    .refine(
      (file) =>
        !(file instanceof File) || ACCEPTED_IMAGE_TYPES.includes(file.type),
      {
        message: 'File type not allowed',
      }
    )
    .optional(),
})

export type PaymentSchema = z.infer<typeof paymentSchema>
