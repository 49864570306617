import { useEffect, useState } from 'react'

import { getEmployeeList, getTeamList } from '../../apis/common.apis'
import { useAuthStore } from '../../store/authStore'
import { useMasterData } from '../../store/masterDataStore'
import { getEmployeeFranchiseeList } from '../employees/api'
import {
  getOrderStatusList,
  getPaymentStatusList,
  getPaymentTypesList,
  getPaymentVerificationStatus,
} from './api'

export const GetFilterDetails = () => {
  const userdata = useAuthStore?.getState()?.userData
  const { masterData } = useMasterData()

  const defaultPages: Record<string, number> = {
    pay_status: 1,
    pay_method: 1,
    pay_type: 1,
    adv_payment: 1,
    created_by_id: 1,
    account_cre: 1,
  }

  const [pages, setPages] = useState(defaultPages)
  const [franchisees, setFranchisees] = useState([])
  const getFranchiseeList = async () => {
    const { data } = await getEmployeeFranchiseeList(
      userdata?.id
      //   , {
      //   all_franchisee_filter: true,
      // }
    )

    const result = data.map((item: any) => ({
      ...item,
      franchisee_display_name:
        item?.franchisee_display_name ?? item?.franchisee_name,
    }))

    setFranchisees(result)
  }
  useEffect(() => {
    if (userdata?.id) {
      getFranchiseeList()
    }
  }, [userdata?.id])
  const loaduserList = async (value?: string, page?: number) => {
    const data = await getEmployeeList({
      search: value ?? '',
      page: page as number,
      type: 'dropdown',
    })
    setPages((prev) => ({
      ...prev,
      created_by_id: data?.next ? prev['created_by_id'] + 1 : 0,
    }))
    return data?.results?.map((item: any) => ({
      ...item,
      user_name: item.name,
    }))
  }

  const loadPaymentStatusList = async (value?: string, page?: number) => {
    const data = await getPaymentStatusList({
      search: value ?? '',
      page: page as number,
      type: 'dropdown',
    })
    setPages((prev) => ({
      ...prev,
      pay_status: data?.next ? prev['pay_status'] + 1 : 0,
    }))
    return data?.data?.map((item: any) => ({
      ...item,
      status_name: item.name,
    }))
  }

  const loadPaymentTypeList = async (value?: string, page?: number) => {
    const data = await getPaymentTypesList({
      search: value ?? '',
      page: page as number,
      type: 'dropdown',
    })
    setPages((prev) => ({
      ...prev,
      pay_type: data?.next ? prev['pay_type'] + 1 : 0,
    }))

    return data?.data?.map((item: any) => ({
      ...item,
      payment_type_list: item.name,
    }))
  }

  const loadPaymentVerificationStatusList = async (
    value?: string,
    page?: number
  ) => {
    const data = await getPaymentVerificationStatus({
      search: value ?? '',
      page: page as number,
      type: 'dropdown',
    })

    return data?.data?.map((item: any) => ({
      ...item,
      verification_status: item.name,
    }))
  }

  const loadTeamLeadList = async (value?: string, page?: number) => {
    const data = await getTeamList({
      search: value ?? '',
      page: page as number,
      type: 'dropdown',
    })

    return data?.results?.map((item: any) => ({
      ...item,
      team_name: generateLabelWithId(item.name, item.lead?.name),
    }))
  }
  const loadOrderStatusList = async (value?: string, page?: number) => {
    const data = await getOrderStatusList({
      search: value ?? '',
      page: page as number,
      list_type: 'dropdown',
    })
    setPages((prev) => ({
      ...prev,
      order_status: data?.next ? prev['order_status'] + 1 : 0,
    }))

    return data?.data?.map((item: any) => ({
      ...item,
      order_status_name: item.name,
    }))
  }
  const generateLabelWithId = (name?: string, lead_name?: string) => {
    return lead_name ? `${name} (${lead_name})` : name
  }

  const amount_paid_list = [
    { id: '0-1000', amount_paid: '0-1000' },
    { id: '1000-2000', amount_paid: '1000-2000' },
    { id: '2000-5000', amount_paid: '2000-5000' },
    { id: '5000-10000', amount_paid: '5000-10000' },
    { id: '10000-25000', amount_paid: '10000-25000' },
    { id: '25000-50000', amount_paid: '25000-50000' },
    { id: '50000-100000', amount_paid: '50000-100000' },
  ]

  return [
    {
      desc: 'franchisee_display_name',
      type: 'custom_search_select',
      descId: 'id',
      async: true,
      isPrimary: false,
      data: franchisees,
      name: 'Franchisee',
      slug: 'franchisee_id',
      objectId: 'franchisee_id',
    },
    {
      type: 'date_picker',
      selectRange: true,
      desc: 'startDate',
      descSecondary: 'endDate',
      isPrimary: true,
      name: 'Transaction Date',
      slug: 'transaction_date',
    },

    {
      desc: 'amount_paid',
      type: 'custom_search_select',
      descId: 'id',
      async: true,
      isPrimary: true,
      paginationEnabled: false,
      data: amount_paid_list,
      name: 'Amount Paid',
      objectId: 'amount',
    },
    {
      desc: 'status_name',
      type: 'multi_select',
      descId: 'stausId',
      async: true,
      paginationEnabled: true,
      nextBlock: pages.pay_status ?? undefined,
      getData: loadPaymentStatusList,
      name: 'Payment Status',
      isPrimary: false,
      objectId: 'status',
      slug: 'payment_status',
    },

    {
      desc: 'name',
      type: 'custom_search_select',
      descId: 'paymentId',
      async: false,
      isPrimary: false,
      paginationEnabled: false,
      data: masterData?.payment_methods,
      name: 'Payment Method',
      objectId: 'payment_method__id',
    },
    {
      desc: 'payment_type_list',
      type: 'auto_suggestion',
      descId: 'paymentTypeId',
      async: true,
      isPrimary: false,
      paginationEnabled: true,
      nextBlock: pages.pay_type ?? undefined,
      getData: loadPaymentTypeList,
      name: 'Payment Type',
      objectId: 'payment_type',
      slug: 'payment_type',
    },
    {
      desc: 'order_status_name',
      type: 'multi_select',
      descId: 'id',
      async: true,
      paginationEnabled: true,
      nextBlock: pages.order_status ?? undefined,
      getData: loadOrderStatusList,
      name: 'Order Status',
      isPrimary: false,
      objectId: 'order__order_status__id',
      slug: 'order_status',
    },
    {
      desc: 'verification_status',
      type: 'auto_suggestion',
      descId: 'id',
      async: true,
      isPrimary: false,
      paginationEnabled: false,
      getData: loadPaymentVerificationStatusList,
      name: 'Verification Status',
      objectId: 'payment_verification_status_id',
      slug: 'verification_status',
    },
    {
      type: 'date_picker',
      selectRange: true,
      desc: 'created_from_date',
      descSecondary: 'created_to_date',
      isPrimary: false,
      name: 'Initiated Date',
      slug: 'initiated_date',
    },
    {
      desc: 'user_name',
      type: 'auto_suggestion',
      descId: 'createdById',
      async: true,
      isPrimary: true,
      paginationEnabled: true,
      nextBlock: pages.created_by_id ?? undefined,
      getData: loaduserList,
      name: 'Initiated By',
      objectId: 'created_by_id',
      slug: 'created_by',
    },
    {
      desc: 'team_name',
      type: 'auto_suggestion',
      descId: 'id',
      async: true,
      isPrimary: false,
      paginationEnabled: false,
      getData: loadTeamLeadList,
      name: 'Team',
      objectId: 'team_id',
      slug: 'team',
    },
  ]
}
