import { zodResolver } from '@hookform/resolvers/zod'
import moment from 'moment'
// import moment from 'moment'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'

import {
  fetchVendorBankAccount,
  getPaymentOutList,
  getPaymentOutMethods,
} from '../../../../apis/common.apis'
import FormBuilder from '../../../../components/app/formBuilder/index'
import CustomDrawer from '../../../../components/common/drawer'
import FormFieldView from '../../../../components/common/inputs/FormFieldView'
import { useSnackbarManager } from '../../../../components/common/snackbar'
import { useAuthStore } from '../../../../store/authStore'
import { getErrorMessage } from '../../../../utilities/parsers'
import { addPayout, deletePayoutFile, updatePayout } from '../../api'
import { ACCEPTED_IMAGE_TYPES, handleSchema } from './schema'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  subSection?: boolean
  handleCallback: () => void
  id?: string
  viewMode?: boolean
  setViewMode?: (value: boolean) => void
  data?: any
  edit: boolean
}

export default function AddPayout({
  isDrawerOpen,
  handleClose,
  handleCallback,
  id,
  viewMode,
  setViewMode,
  data,
  edit,
}: Props) {
  const [method, setMethod] = useState()
  const vendor = useAuthStore.getState().userData.vendor
  const [bankAccounts, setBankAccounts] = useState<any>([])
  const paymentSchema = handleSchema(method, data)
  type PaymentSchema = z.infer<typeof paymentSchema>
  const [payoutType, setPayoutType] = useState<any>([])
  const [payoutMethod, setPayoutMethod] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbarManager()
  const { userData } = useAuthStore()
  //   const todayDate = moment().toDate()
  //   const formatedDate = moment(todayDate).format('DD-MM-YYYY')
  const onInit = () => {
    return {}
  }
  const getBankAccountList = () => {
    fetchVendorBankAccount({
      id: vendor as string,
      input: { page: 1, page_size: 30 },
    }).then((res) => {
      setBankAccounts(res?.data?.results)
    })
  }
  const methods = useForm<PaymentSchema>({
    resolver: zodResolver(paymentSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...onInit(),
    },
  })

  useEffect(() => {
    if (isDrawerOpen) {
      if (!viewMode) {
        getBankAccountList()
        getPayoutMethodsList()
        getPayoutList()
      }
      if (!viewMode && !edit) {
        methods.reset({})
      } else {
        methods.reset({
          paymentout_type: data?.paymentout_type?.id,
          paymentout_type_name: data?.paymentout_type?.display_name,
          payment_date: data?.payment_date
            ? moment(data?.payment_date, 'YYYY-MM-DD').toString()
            : '',
          amount: data?.amount ? parseFloat(data?.amount) : 0,
          paymentout_method_id: data?.paymentout_method?.id,
          paymentout_method_name: data?.paymentout_method?.display_name,
          transaction_number: data?.transaction_number,
          receipt_number: data?.receipt_number,
          remark: data?.remark,
          // bizpole_payable_amount: data?.bizpole_payable_amount,
        })
      }
    }
  }, [data, viewMode, edit, isDrawerOpen])

  useEffect(() => {
    extractAccount(data?.account_name)
  }, [bankAccounts])

  const extractAccount = (account_name: string) => {
    bankAccounts.map((item: any) => {
      if (item.account_name == account_name) {
        methods.reset({
          ...methods.watch(),
          bank_account_id: item.id,
          bank_account_name: item.account_display_name
            ? item.account_display_name
            : item.account_name,
        })
      }
    })
  }

  const getPayoutMethodsList = async () => {
    await getPaymentOutMethods({ is_active: true }).then((res) => {
      setPayoutMethod(res?.data)
    })
  }
  const getPayoutList = async () => {
    await getPaymentOutList({ show_in_vendor: true }).then((res) => {
      setPayoutType(res?.data)
    })
  }
  const handleDeleteFile = () => {
    deletePayoutFile(data?.id)
      .then(() => {
        enqueueSnackbar('File removed successfully', { variant: 'success' })
        handleCallback?.()
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.error, { variant: 'error' })
      })
  }

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    type = 'text',
    disabled = false
  ) => ({
    name,
    label,
    id: name,
    type: type,
    placeholder,
    ...(required ? { required: true } : {}),
    ...(disabled ? { disabled: true } : {}),
  })
  const paymentMethodCallback = (d: any) => {
    setMethod(d.code)
    if (d.code === 'cash') {
      methods.clearErrors('bank_account_id')
      methods.clearErrors('bank_account_name')
    }
  }
  const formBuilderProps = [
    {
      name: 'payout_id',
      label: 'Payout ID',
      value: data?.unique_id,
      hidden: !viewMode,
    },
    {
      name: 'paymentout_type_name',
      label: 'Payment Type',
      required: true,
      data: payoutType,
      async: false,
      id: 'paymentout_type',

      descId: 'id',
      // initialLoad: true,
      desc: 'name',
      type: 'custom_select',
      placeholder: 'Enter Payment Type',
      value: data?.paymentout_type?.name,
    },
    {
      ...textField(
        'payment_date',
        'Date of Payment',
        'Enter transaction date',
        true
      ),
      maxDate: new Date(),
      type: 'date',
      value: data?.payment_date,
    },
    {
      ...textField('amount', 'Amount', 'Amount', true, 'number'),
      type: 'number',
      id: 'amount',
      value: data?.amount,
    },
    // {
    //   ...textField(
    //     'bizpole_payable_amount',
    //     'Bizpole Payable Amount',
    //     'Bizpole Payable Amount'
    //   ),
    //   type: 'number',
    //   value: data?.bizpole_payable_amount,
    // },
    {
      name: 'paymentout_method_name',
      label: 'Mode of Payment',
      handleCallBack: paymentMethodCallback,
      data: payoutMethod,
      required: true,
      id: 'paymentout_method_id',

      descId: 'id',
      // initialLoad: true,

      desc: 'name',
      type: 'custom_select',
      placeholder: 'Enter Mode of Payment',
      value: data?.paymentout_method?.name,
    },
    {
      ...textField(
        'transaction_number',
        'Transaction number',
        'Enter Transaction number',
        true,
        'text'
      ),
      value: data?.transaction_number,
    },
    {
      ...textField(
        'receipt_number',
        'Receipt number',
        'Enter Receipt number',
        true,
        'text'
      ),
      value: data?.receipt_number,
    },
    {
      name: 'bank_account_name',
      label: 'Bank Account',

      data: bankAccounts,
      required:
        method === 'cash' || data?.paymentout_method?.code === 'cash'
          ? false
          : true,
      id: 'bank_account_id',

      descId: 'id',
      // initialLoad: true,

      desc: 'account_display_name',
      type: 'custom_select',
      placeholder: 'Enter bank account',
      hidden: viewMode,
      disabled: edit,
    },
    {
      name: 'name_in_account',
      label: 'Name in Account',
      value: data?.account_name,
      hidden: !viewMode,
    },
    {
      name: 'account_number',
      label: 'Account Number',
      value: data?.account_number,
      hidden: !viewMode,
    },
    {
      name: 'bank_name',
      label: 'Bank Name',
      value: data?.bank_name,
      hidden: !viewMode,
    },
    {
      name: 'branch_name',
      label: 'Branch Name',
      value: data?.branch_name,
      hidden: !viewMode,
    },
    {
      name: 'ifsc_code',
      label: 'IFSC Code',
      value: data?.ifsc_code,
      hidden: !viewMode,
    },
    {
      name: 'account_type',
      label: 'Account Type',
      value: data?.account_type,
      hidden: !viewMode,
    },

    {
      ...textField('remark', 'Remarks', 'Enter Remarks', false),
      type: 'textarea',
      value: data?.remark,
    },

    {
      name: 'payment_document',
      required: false,
      id: 'payment_document',
      descId: 'id',
      selectedFiles: data?.original_document
        ? { name: data?.original_document, link: data?.payment_document }
        : '',
      handleDeleteFile: handleDeleteFile,
      type: 'file_upload',
      label: viewMode ? 'Payment Document' : '',
      acceptedFiles: 'JPEG,JPG,PNG,PDF',
      supportedExtensions: ACCEPTED_IMAGE_TYPES,
      supportedFiles: ACCEPTED_IMAGE_TYPES,
      link: data?.payment_document,
      value: data?.original_document,
    },
  ]

  const handleSubmission = () => {
    methods.reset({})
    handleCallback()
    handleClose()
  }

  const onSubmit = (form_data: PaymentSchema) => {
    setIsLoading(true)
    const input: any = {
      amount: form_data?.amount ?? '',
      // bizpole_payable_amount: form_data?.bizpole_payable_amount ?? '',
      paymentout_method: form_data?.paymentout_method_id ?? '',
      payment_date: form_data?.payment_date
        ? moment(form_data?.payment_date).format('YYYY-MM-DD')
        : '',
      transaction_number: form_data?.transaction_number ?? '',
      paymentout_type: form_data?.paymentout_type ?? '',
      receipt_number: form_data?.receipt_number ?? '',
      bank_account_id: form_data?.bank_account_id ?? '',
      remark: form_data?.remark ?? '',
      vendor: userData?.vendor,
      ...(form_data?.payment_document
        ? { payment_document: form_data?.payment_document }
        : {}),
    }
    const payload = new FormData()
    Object.keys(input).forEach((k) => {
      payload.append(k, input[k])
    })
    const apiFun = data?.id
      ? updatePayout(data?.id, payload)
      : addPayout({ input: payload, service_id: id ?? '' })
    apiFun
      .then(() => {
        enqueueSnackbar(
          getErrorMessage(
            data?.id
              ? 'Payout Updated Successfully'
              : 'Payout Created Successfully'
          ),
          {
            variant: 'success',
          }
        )
        handleSubmission()
        setIsLoading(false)
      })
      .catch((err) => {
        enqueueSnackbar(getErrorMessage(err.response.data.error), {
          variant: 'error',
        })
        setIsLoading(false)
      })
  }

  const { handleSubmit } = methods

  const handleClearAndClose = () => {
    methods.reset({})
    setViewMode?.(false)
    handleClose()
  }
  return (
    <CustomDrawer
      className="formDrawer"
      open={isDrawerOpen}
      handleClose={() => handleClearAndClose()}
      actionLoader={isLoading}
      handleSubmit={handleSubmit((data) => onSubmit(data))}
      title={edit ? 'Edit Payment Out' : 'Payment Out'}
      disableSubmit={isLoading}
      hideSubmit={viewMode}
    >
      <div className="flex flex-col gap-4">
        {!viewMode ? (
          <FormProvider {...methods}>
            <FormBuilder data={formBuilderProps} edit={true} />
          </FormProvider>
        ) : (
          formBuilderProps.map((data: any) => (
            <FormFieldView
              value={data?.value}
              label={data?.label}
              key={data?.id}
              type={data?.type}
              link={data?.link}
              hidden={data?.hidden}
            />
          ))
        )}
      </div>
    </CustomDrawer>
  )
}
