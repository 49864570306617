import {
  getNestedProperty,
  useCurrencyFormat,
} from '../../../../../utilities/parsers'

export const getColumns = () => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    const format = useCurrencyFormat

    if (isCustom === 'amount') {
      const propertyValue = getNestedProperty(row, key)
      const numbers =
        typeof propertyValue === 'string' ? parseFloat(propertyValue) : 0

      return {
        cell: format(numbers),
        toolTip: getNestedProperty(row, key) ?? '',
      }
    } else {
      return {
        cell: getNestedProperty(row, key),
        toolTip: getNestedProperty(row, key) ?? '',
      }
    }
  }

  const column = [
    {
      title: 'Service Name ',
      field: 'service_name',
      width: 200,
      isVisible: true,
      fixed: true,
      resizable: true,
      customCell: true,
    },
    {
      title: 'Distribution Amount ',
      field: 'distribution_amount',
      width: 200,
      customCell: true,
      isVisible: true,
      align: 'right',
      renderCell: createRenderCell('distribution_amount', 'amount'),
    },
    {
      title: 'Professional Fee ',
      field: 'professional_fee',
      width: 200,
      customCell: true,
      isVisible: true,
      align: 'right',
      renderCell: createRenderCell('professional_fee', 'amount'),
    },
    {
      title: 'Vendor Fee ',
      field: 'vendor_fee',
      width: 200,
      customCell: true,
      isVisible: true,
      align: 'right',
      renderCell: createRenderCell('vendor_fee', 'amount'),
    },
    {
      title: 'Govt. Fee ',
      field: 'govt_fee',
      width: 200,
      customCell: true,
      isVisible: true,
      align: 'right',
      renderCell: createRenderCell('govt_fee', 'amount'),
    },
    {
      title: 'GST ',
      field: 'gst_amount',
      width: 200,
      customCell: true,
      isVisible: true,
      align: 'right',
      renderCell: createRenderCell('gst_amount', 'amount'),
    },
  ]
  return column
}
